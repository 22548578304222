// MODAL DIALOG
// ----------------------------------------------------------------------------
.is--modal-open {
  @media only screen and (max-width: $mq-mobile-landscape) {
    overflow: hidden;
    // position: fixed;
    // top: 0;
    // bottom: 0;
    width: 100%;
  }
}
.mt-modal {
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  display: none;
  -webkit-animation: moveUpFadeIn 0.3s cubic-bezier(0.51, 0.92, 0.24, 1.15);
  animation: moveUpFadeIn 0.3s cubic-bezier(0.51, 0.92, 0.24, 1.15);
  pointer-events: none;

  &.is--visible {
    opacity: 1;
    display: flex !important;
    z-index: 100010;
  }

  &__wrap {
    background-color: $lighter-grey-color;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
    overflow: auto;
    width: 80%;
    max-width: 600px;
    pointer-events: auto;

    @media only screen and (max-width: $mq-mobile-landscape) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transform: none;
      width: 100%;
    }
  }

  // MODAL HEADER
  // --------------------------------------------------------
  &__header {
    background-color: $blue-color;
    padding: 16px 60px 16px 20px;
    position: relative;
    height: 56px;

    @media only screen and (max-width: $mq-mobile-landscape) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 100;
    }
  }
  &__title {
    color: white;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;

    @media only screen and (max-width: $mq-mobile-landscape) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__close-btn {
    color: white;
    cursor: pointer;
    font-size: 20px;
    line-height: 24px;
    padding: 16px 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    transition: all 0.2s ease-out;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }

    svg {
      fill: white;
    }
  }

  // MODAL BODY
  // --------------------------------------------------------
  &__body {
    @media only screen and (max-width: $mq-mobile-landscape) {
      overflow: auto;
      position: absolute;
      top: 56px;
      bottom: 0;
      padding-bottom: 56px;
      width: 100%;
    }
  }
  &__body-section {
    background-color: $lighter-grey-color;
    border-bottom: 1px solid $grey-color;
    padding: 20px;

    &:last-child {
      border-bottom: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }

    &.mt-radio {
      padding: 0;
    }
  }

  // MODAL FOOTER
  // --------------------------------------------------------
  &__footer {
    background-color: $lighter-grey-color;
    border-top: 1px solid $grey-color;
    padding: 12px 20px;

    @media only screen and (max-width: $mq-mobile-landscape) {
      padding: 12px;
      position: fixed;
      bottom: 0;
      text-align: center;
      width: 100%;
    }
  }
}

// OVERLAY
// --------------------------------------------------------
.mt-overlay {
  background: #000;
  cursor: pointer;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  transition: z-index 0.1s 0.2s, opacity 0.2s ease-out;
  width: 100%;
  height: 100%;
  z-index: -10;

  .is--modal-open & {
    opacity: 0.7;
    transition: z-index 0.1s, opacity 0.2s 0.1s ease-out;
    z-index: 100000;
  }
}

// MEDIA OBJECT
// ----------------------------------------------------------------------------
.mt-media {
  display: flex;
  align-items: stretch;

  @media only screen and (max-width: $mq-mobile-landscape) {
    display: block;
  }

  &__media {
    display: flex;
    align-items: center;
    background-color: white;
    float: left;
    width: 33.333%;

    @media only screen and (max-width: $mq-mobile-landscape) {
      float: none;
      width: 100%;
    }
  }
  &__media-bg-icon {
    color: #666;
    display: block;
    font-size: 100px;
    margin: 0 auto;
    text-align: center;
  }
  &__content {
    float: right;
    width: 66.666%;

    @media only screen and (max-width: $mq-mobile-landscape) {
      float: none;
      width: 100%;
    }
  }
}

// OLD CLASSES MAPPING
// ----------------------------------------------------------------------------
.modal-dialog {
  @extend .mt-modal;
  background-color: white;
  display: block;
  margin: 0;
  opacity: 1;
  position: static;
  top: 0;
  transform: translateY(0);
  -webkit-animation: moveUpFadeInLegacy 0.3s
    cubic-bezier(0.51, 0.92, 0.24, 1.15);
  animation: moveUpFadeInLegacy 0.3s cubic-bezier(0.51, 0.92, 0.24, 1.15);

  &.row {
    margin: 0;
  }

  @media only screen and (max-width: $mq-mobile-landscape) {
    margin-top: 0 !important;
    top: 0 !important;
  }
}
.title-dialog {
  @extend .mt-modal__header;
  @extend .mt-modal__title;
}
.modal-close {
  @extend .mt-modal__close-btn;
}
.content-dialog {
  @extend .mt-modal__body;
  @extend .mt-modal__body-section;
  display: block;
  margin: 0;
  border-bottom: 0;

  @media only screen and (max-width: $mq-mobile-landscape) {
    &.grid-8 {
      position: static;
    }
  }
}
.actions-dialog {
  @extend .mt-modal__footer;
}
.pic-dialog {
  @extend .mt-media__media;
  width: 33.333%;

  @media only screen and (max-width: $mq-mobile-landscape) {
    position: static;
    margin-top: 56px;
  }

  &::before {
    @extend .mt-media__media-bg-icon;
  }
}
#overlay {
  @extend .mt-overlay;
  display: none;
}
