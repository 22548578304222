﻿// BASIC NAV
// ----------------------------------------------------------------------------
.nav_btn {
  display: block;
  background-color: #fff;
  font-family: $font, arial, verdana;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  padding: 12px 20px;
  text-decoration: none;

  @media only screen and (min-width: $mq-tablet) {
    font-size: 16px;
  }

  [class^='icon-'] {
    color: #434b51;
    display: inline-block;
    font-size: 20px;
    line-height: 24px;
    margin-right: 32px;

    @media only screen and (min-width: $mq-tablet) {
      margin-right: 20px;
    }
  }
  &,
  &:visited {
    color: #434b51;
  }
  &:hover,
  &:focus {
    &,
    [class^='icon-'] {
      color: $link-hover-color;
    }
  }
  &.is--active {
    background: white;
    // border-bottom: 1px solid $light-grey-color;
    font-weight: 600;
  }
  &.has--extra-padding {
    padding-top: 20px;

    @media only screen and (min-width: $mq-tablet) {
      padding-top: 12px;
    }
  }
  &.has--border-top {
    border-top: 1px solid #e0dfdd;
  }
  &.has--border-bottom {
    border-bottom: 1px solid #e0dfdd;
  }
  &--blue {
    @media only screen and (max-width: $mq-tablet - 1) {
      color: $link-color;
    }
  }
}

// NAV SLIDE
// ----------------------------------------------------------------------------
@media only screen and (max-width: $mq-tablet - 1) {
  .nav-slide {
    position: absolute;
    top: 0;
    bottom: 100%;
    left: 0;
    right: 0;
    transition: all 0.1s 0.4s linear;
    z-index: 2001;

    &_open-btn {
      display: block;

      .icon-menu {
        color: $red-color;
        display: block;
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
      }
    }
    &_mask {
      background-color: #000;
      cursor: pointer;
      opacity: 0;
      position: fixed;
      top: 0;
      bottom: 0;
      left: -100%;
      transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      width: 100%;
      z-index: -1;
    }
    &_content {
      background-color: $lightest-grey-color;
      background-size: cover;
      overflow: auto;
      padding-top: 64px;
      padding-bottom: 16px;
      position: absolute;
      top: 0;
      bottom: 0;
      transform: translateX(-100%);
      width: 290px;
      transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  .is--nav-slide-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;

    .nav-slide {
      bottom: 0;
      transition: all 0.1s linear;

      &_mask {
        opacity: 0.6;
        left: 0;
        transition: opacity 0.4s 0.1s cubic-bezier(0.4, 0, 0.2, 1);
        z-index: 0;
      }
      &_content {
        transform: translateX(0);
        transition: transform 0.4s 0.1s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }
}

// SIDE NAV
// ----------------------------------------------------------------------------
.side-nav {
  span {
    font-size: 15px;
    font-weight: 700;
    display: block;
    margin: 20px 0 5px;
  }

  > li a {
    color: #666;
    display: block;
    padding: 5px 15px 5px 25px;

    &:hover {
      box-shadow: 1px 0 0 #ccc inset;
    }
    &.active {
      box-shadow: 5px 0 0 $blue-color inset !important;
      color: $blue-color !important;
    }
  }
  ul a {
    font: 14px/15px Arial, Verdana;
    line-height: 15px;
    padding-left: 15px;

    &.active {
      box-shadow: 1px 0 0 $blue-color inset !important;
    }
  }
}

// Common top header menu
// ----------------------------------------------------------------------------
// header {
//     background:#FFF;
//     box-shadow:0 2px 3px rgba(0,0,0,.25);
// }

.nav {
  > ul {
    display: none;
    height: auto;
  }
  li {
    display: block;
    float: none;
    width: 100%;
  }
  a {
    color: #333;
    display: block;
    font-family: 'coches', Verdana, Arial;
    font-size: 15px;
    line-height: 60px;

    &:hover {
      color: #000;
    }
    &.active {
      box-shadow: inset 0 -5px 0 #e7e7e7;
      color: #000;
    }
  }
  span {
    font-size: 24px;
    color: $red-color;
    font-weight: bold;
    margin: 13px 0 0 5px;
    display: inline-block;
  }
}

@media only screen and (min-width: $mq-tablet) {
  .nav {
    > ul {
      display: block;
    }
    li {
      display: inline-block;
      width: auto;
    }
    ul a {
      [class^='icon-'] {
        display: none;
      }
    }
    .pull {
      display: none;
    }
  }
}
