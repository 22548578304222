﻿footer {
  display: none;
}

/* Tablets and up */
@media only screen and (min-width: 768px) {
  footer {
    display: block;
  }

  #social {
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;

    .fleft {
      a {
        display: inline-block;
        width: 70px;
        height: 80px;
        color: #606f80;
        line-height: 75px;
        text-align: center;
        float: left;
        font-size: 22px;

        span {
          vertical-align: middle;
        }
        &:hover span {
          color: #eef0f4;
        }
      }
      .separator {
        display: inline-block;
        width: 1px;
        height: 50px;
        background-color: #d1cdcc;
        vertical-align: middle;
        float: left;
        margin-top: 15px;
      }
    }
    .facebook:hover {
      background-color: #3b5998;
    }
    .twitter:hover {
      background-color: #00aced;
    }
    .google:hover {
      background-color: #dd4b39;
    }
    .youtube:hover {
      background-color: #bb0000;
    }
    .fright {
      padding: 20px 20px 10px;

      h3 {
        margin: 0 20px 0 0;
        color: #606f80;
        line-height: 40px;
        font-size: 14px;
      }
      input {
        width: 150px;
        border-radius: 3px 0 0 3px;
        font-size: 15px;
        height: 37px;
        float: left;
        border: none;
        margin: 0;
      }
    }
  }

  .go-input {
    float: left;
    border-radius: 0 3px 3px 0;
    background-color: #f5f0ec;

    span {
      color: #606f80;
      line-height: 37px;
      padding: 0 10px;
      font-size: 15px;
    }
  }

  #footer-links {
    margin-top: 40px;

    a {
      color: #606f80;
      font: 14px coches, arial, verdana;

      &:hover {
        color: #000e23;
      }
    }
    ul {
      margin-right: 30px;
      padding: 0;
    }

    h4 {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 8px;
      color: #000e23;
    }
    .appstore,
    .playstore {
      border: 1px solid #606f80;
      border-radius: 3px;
      width: 140px;
      height: 50px;
      display: inline-block;
      margin-right: 20px;
    }
    .appstore {
      background: url(/img/sprite2015.png) no-repeat 0 -68px;
    }
    .playstore {
      background: url(/img/sprite2015.png) no-repeat 0 -118px;
    }
  }

  #schibsted {
    margin: 40px 0;

    p {
      font-size: 12px;
      color: #606f80;
    }
    a {
      font: 12px coches, arial, verdana;
      color: #606f80;
      font-weight: 600;
    }
    a:hover {
      color: #434b51;
      border: none;
    }
  }

  .logo-schibsted {
    display: inline-block;
    width: 140px;
    height: 30px;
    background: url(../img/sprite2015.png) no-repeat 0 -162px;
    margin-bottom: 20px;
  }

  .logo-adevinta {
    display: inline-block;
    width: 114px;
    height: auto;
    margin-bottom: 20px;
  }
}

/* page(980) + (20 marginleft + 20 marginright) and up */
@media only screen and (min-width: 1020px) {
  #social {
    .fright {
      h3 {
        font-size: 24px;
      }
      input {
        width: 200px;
      }
    }
    .fleft {
      a {
        width: 80px;
      }
    }
  }
  #footer-links {
    ul {
      margin-right: 50px;
    }
    a {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
}

.mt-SocialNetworks {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 8px;
  height: 72px;

  &-item {
    display: inline-block;

    &:hover {
      .mt-SocialNetworks-icon {
        fill: #434b51;
      }

      + .mt-SocialNetworks-item {
        // scss-lint:disable NestingDepth
        .mt-Icon {
          border-color: transparent;
        }
      }
    }
  }

  &-link {
    display: block;
    padding: 24px 16px;
    text-align: center;

    &:hover {
      .mt-Icon {
        fill: #fff;
      }
    }

    &--youtube:hover {
      background: #bb0000;

      .mt-Icon {
        border-left: 1px solid #bb0000;
      }
    }

    &--twitter:hover {
      background: #55acee;

      .mt-Icon {
        border-left: 1px solid #55acee;
      }
    }

    &--instagram:hover {
      background: #8a3ab9;
      .mt-Icon {
        border-left: 1px solid #8a3ab9;
      }
    }

    &--facebook:hover {
      background: #3b5998;

      .mt-Icon {
        border-left: 1px solid #3b5998;
      }
    }

    &--tiktok:hover {
      background: #69c9d0;

      .mt-Icon {
        border-left: 1px solid #69c9d0;
      }
    }
  }

  .mt-Icon {
    display: block;
    fill: #434b51;
    height: 24px;
    width: 24px;
    padding: 0;
  }
}
