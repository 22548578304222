// mt-SmartBanner
//
//
// Markup:
/*
<h3>Mobile</h3>
<div class="kss-Boxtransparent kss-Boxtransparent--mobile u-mb--large">
  <div class="iframify">
    <div class="mt-SmartBanner">
      <button class="mt-SmartBanner-closeBtn" data-banner-top="close">
        <span class="u-hide--visually">Cerrar</span>
        <svg class="mt-Icon mt-Icon--light">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../img/sprite/icons.svg#close"></use>
        </svg>
      </button>
      <img src="http://placehold.it/70x70?text=icon" width="70" height="70" />
      <div class="mt-SmartBanner-content">
        <div class="mt-SmartBanner-info">
          <p>Coches.net <strong>para IOS</strong></p>
          <p class="mt-SmartBanner-stars">
             <svg class="mt-Icon mt-Icon--small">
               <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../img/sprite/icons.svg#star-full"></use>
            </svg>
             <svg class="mt-Icon mt-Icon--small">
               <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../img/sprite/icons.svg#star-full"></use>
            </svg>
             <svg class="mt-Icon mt-Icon--small">
               <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../img/sprite/icons.svg#star-full"></use>
            </svg>
             <svg class="mt-Icon mt-Icon--small">
               <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../img/sprite/icons.svg#star-full"></use>
            </svg>
             <svg class="mt-Icon mt-Icon--small">
               <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../img/sprite/icons.svg#star-empty"></use>
            </svg>
            (999.999)
          </p>
        </div>
        <div class="mt-SmartBanner-cta">
          <button class="mt-SmartBanner-installBtn mt-Button mt-Button--secondary mt-Button--small">Instalar</button>
        </div>
      </div>
    </div>
  </div>
</div>
*/

.mt-SmartBanner {
  align-items: center;
  background-color: $c-neutro-lighter;
  border-bottom: 1px solid $c-neutro;
  bottom: 0;
  display: flex;
  left: 0;
  padding: 0.5rem;
  padding-left: 0;
  position: fixed;
  right: 0;

  @media screen and (min-width: 600px) {
    display: none;
  }

  &-closeBtn {
    background: transparent;
    border: 0;
    color: $c-neutro;
    padding: 0;
  }

  &-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 12px;
    justify-content: flex-end;

    @media screen and (min-width: 600px) {
      flex-direction: row;
    }
  }

  &-info {
    p {
      margin-bottom: 0;
      text-align: right;

      @media screen and (max-width: 600px) {
        &:last-child {
          margin-bottom: 0.25rem;
        }
      }
    }
  }

  &-stars {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    .mt-Icon {
      fill: $c-yellow;
      padding: 0;
      width: 1rem;

      &:last-child {
        margin-right: 0.5rem;
      }
    }
  }

  &-cta {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-left: 0.75rem;
  }
}

// FIX
.mt-SmartBanner {
  z-index: 5001;

  * {
    box-sizing: border-box;
  }
  img {
    display: block;
  }
  p {
    font-size: 12px;
  }
  &-info p span,
  &-info p strong {
    font-size: 12px;
  }
  &-info p strong {
    font-weight: bold;
  }
}

body .mt-SmartBanner-demo {
  position: relative;
}

.mt-SmartBanner-cta button {
  -webkit-appearance: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Open Sans', Arial, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: 2.5rem;
  line-height: 1.5rem;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  border-color: rgb(69, 133, 242);
  overflow: visible;
  outline: none;
  transition: all 0.3s ease-out;
  background-color: rgb(69, 133, 242);
  font-size: 0.875rem;
  height: 1.75rem;
  line-height: 1.25rem;
  padding: 0.25rem 1rem;
}

.mt-SmartBanner-closeBtn {
  color: rgb(202, 198, 195);
  background: transparent;
  border-width: 0;
  padding: 0;
  align-items: flex-start;
  text-align: center;
  cursor: default;
  letter-spacing: normal;
  word-spacing: normal;

  .mt-Icon {
    display: inline-block;
    fill: rgb(95, 94, 93);
    height: 2rem;
    padding: 0.375rem;
    vertical-align: top;
    width: 2rem;
  }

  .u-hide--visually {
    clip: rect(0 0 0 0);
    height: 1px;
    position: absolute;
    width: 1px;
    border-width: 0;
    border-style: initial;
    border-color: initial;
    margin: -1px;
    overflow: hidden;
    padding: 0;
  }
}

.mt-SmartBanner-info p {
  line-height: 18px;
  font-size: 12px;

  @media screen and (max-width: 600px) {
    &:last-child {
      margin-bottom: 0.25rem;
    }
  }
}

.mt-SmartBanner-stars .mt-Icon {
  height: 1rem;
}
