/* // TOAST
// ----------------------------------------------------------------------------
.mt-toast {
    box-sizing: border-box;
    background-color: white;
    border-bottom: 4px solid;
    box-shadow: 0 4px 6px rgba(0,0,0,.2);
    padding-bottom: 24px;
    text-align: center;

    [class^="icon"] {
        display: inline-block;
        font-size: 48px;
        line-height: 1;
        margin-bottom: 8px;
        padding-top: 24px;
        padding-bottom: 0;
        padding-left: 24px;
        padding-right: 24px
    }
    &__title {
        color: white;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 24px;
        padding-right: 24px;
    }
    &__text {
        color: $dark-grey-color;
        font-family: Arial, Verdana;
        font-size: 16px;
        line-height: 1.45;
        margin-bottom: 16px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 24px;
        padding-right: 24px;
    }
    > :last-child {
        margin-bottom: 0;
    }


    // MODIFIERS
    // --------------------------------------------------------
        &--ok {
            color: $green-color;
            .mt-toast__title { background-color: $green-color; }
        }
        &#{&}--ok {
            &.mt-toast-fixDrive2 > .mt-Toast-icon {
                fill: $green-color;
            }
        }

        &--info {
            color: $blue-color;
            .mt-toast__title { background-color: $blue-color; }
        }
        &#{&}--info {
            &.mt-toast-fixDrive2 > .mt-Toast-icon {
                fill: $blue-color;
            }
        }

        &--warning {
            color: $orange-color;
            .mt-toast__title { background-color: $orange-color; }
        }
        &#{&}--warning {
            &.mt-toast-fixDrive2 > .mt-Toast-icon {
                fill: $orange-color;
            }
        }

        &--error {
            color: $red-color;
            .mt-toast__title { background-color: $red-color; }
        }
        &#{&}--error {
            &.mt-toast-fixDrive2 > .mt-Toast-icon {
                fill: $red-color;
            }
        }

    &--fixed {
        margin: 0 auto;
        position: fixed;
            top: 50%;
            left: 0;
            right: 0;
        transform: translateY(-50%);
        width: 100%;
        min-width: 168px;
        max-width: 200px;
        z-index: 11000;
    }

    &.mt-toast-fixDrive2 {
        & > .mt-Toast-icon {
            display: inline-block;
            fill: $dark-grey-color;
            height: 4rem;
            padding-top: 1rem;
            width: 4rem;
        }
        &.anim-move-up-fade-in {
            animation-name: none;
        }
    }
} */

// Se utiliza la nomenclatura del mt-Toast de drive2 ya que se está utilizando el mismo componente en drive1

.mt-Toast {
  background-color: #fff;
  border-bottom: 4px solid;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding-bottom: 24px;
  text-align: center;
}

.mt-Toast-icon {
  display: inline-block;
  fill: #5f5e5d;
  height: 4rem;
  padding-top: 1rem;
  width: 4rem;
}

.mt-Toast-title {
  color: #959290;
  font-size: 16px;
  font-weight: 700;
  padding: 5px 10px;
}

.mt-Toast-text {
  color: #959290;
  font-size: 16px;
  line-height: 1.45;
  margin-bottom: 16px;
  padding: 0 24px;
}

.mt-Toast > :last-child {
  margin-bottom: 0;
}

.mt-Toast--ok,
.mt-Toast--ok .mt-Toast-text {
  color: #27ae60;
}

.mt-Toast--ok .mt-Toast-icon {
  fill: #27ae60;
}

.mt-Toast--info,
.mt-Toast--info .mt-Toast-text {
  color: #4585f2;
}

.mt-Toast--info .mt-Toast-icon {
  fill: #4585f2;
}

.mt-Toast--warning,
.mt-Toast--warning .mt-Toast-text {
  color: #f39c12;
}

.mt-Toast--warning .mt-Toast-icon {
  fill: #f39c12;
}

.mt-Toast--error,
.mt-Toast--error .mt-Toast-text {
  color: #e74c3d;
}

.mt-Toast--error .mt-Toast-title {
  background-color: #e74c3d;
}

.mt-Toast--error .mt-Toast-icon {
  fill: #e74c3d;
}

.mt-Toast--fixed {
  left: 0;
  margin: 0 auto;
  max-width: 200px;
  min-width: 168px;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 11000;
}

.mt-Toast.anim-move-up-fade-in {
  animation-name: none;
}
