﻿.card {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);

  .content-card {
    padding: 10px;
  }
  a {
    display: block;
  }
  p {
    font-family: coches, arial, verdana;
    font-size: 15px;
    margin: 0;
    color: #000;
  }
  img {
    margin: 0;
    vertical-align: bottom;
    width: 100%;
  }
  h3 {
    font-weight: bold;
    margin: 10px 0 0;
    border-top: 1px solid #f1f1f1;
    padding-top: 10px;
  }
  [class^='icon-'] {
    color: #bbb;
    font-size: 12px;

    &:before {
      margin-right: 3px;
      font-size: 16px;
      vertical-align: -3px;
    }
  }
  .icon-play {
    font-size: 16px;
  }

  &-top {
    position: relative;
    margin-bottom: 20px;
  }

  &-status {
    background-color: #fff;
    border-radius: 100px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    font-size: 12px;
    line-height: 23px;
    margin-left: 50%;
    margin-top: 10px;
    padding: 3px 10px;
    position: absolute;
    text-transform: uppercase;
    transform: translate(-50%);
    white-space: nowrap;
    z-index: 2;
  }
  &-info {
    background-color: #fff;
    border-radius: 100px;
    bottom: -11px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    font-size: 12px;
    line-height: 23px;
    margin-left: 50%;
    padding: 3px 10px;
    position: absolute;
    text-transform: uppercase;
    transform: translate(-50%);
    white-space: nowrap;
    z-index: 2;

    .icon {
      color: #ffffff;
    }

    &.success {
      background-color: $green-color;
      color: #fff;
    }
    &.warning {
      background-color: $orange-color;
      color: #fff;
    }
    &.error {
      background-color: $red-color;
      color: #fff;
    }
    &.moderation {
      background-color: $blue-color;
      color: #fff;
    }
  }
  .card-title,
  .card-title:hover {
    color: $c-gray-darkest;
  }
  &.not-published {
    .card-image,
    .card-title {
      opacity: 0.3;
    }
  }
  &-stats {
    background-color: $c-neutro-lightest;
    border-top: 1px solid $c-neutro-lighter;
    padding-top: 15px;

    &-col {
      width: 50%;
      float: left;
      margin-bottom: 15px;

      h4 {
        font-size: 15px;
        margin-bottom: 5px;
        color: $c-neutro-dark;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px;
      }
      h5 {
        font-size: 13px;
        margin-bottom: 5px;
        color: $c-neutro-dark;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px;
      }
    }
  }
}

.label-news {
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  border-radius: 3px;
  padding: 0 5px;
  font-family: coches, arial, verdana;
  font-size: 14px;
  background-color: $grey-color;
}
