﻿// TIPOGRAFIA

@font-face {
  font-family: 'coches';
  font-style: normal;
  font-weight: 400;
  src: url('https://s.ccdn.es/fonts/PoppinsMotor-400.otf') format('opentype');
}
@font-face {
  font-family: 'coches';
  font-style: normal;
  font-weight: 600;
  src: url('https://s.ccdn.es/fonts/PoppinsMotor-600.otf') format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('https://s.ccdn.es/fonts/PoppinsMotor-400.otf') format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('https://s.ccdn.es/fonts/PoppinsMotor-600.otf') format('opentype');
}

div,
li,
p {
  font-family: coches, arial, verdana;
  font-size: 16px;
  line-height: 1.45;
}
b,
strong,
span {
  line-height: 20px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: coches, arial, verdana;
  font-weight: 300;
  color: #606f80;
  padding: 0;
  margin: 0 0 20px 0;
  line-height: 1.1;
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 26px;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 18px;
}
p {
  margin: 0 0 10px;
}

.font,
.font span,
.font p,
.font b {
  font-family: coches, arial;
}
.font,
.font span,
.font p {
  font-weight: 300;
}
.font b,
.font strong {
  font-weight: 600;
}
.fs12,
.fs12 span {
  font-size: 12px !important;
}
.fs16,
.fs16 span {
  font-size: 16px !important;
}
.fs20,
.fs20 span {
  font-size: 20px !important;
}
.fs25,
.fs25 span {
  font-size: 25px !important;
}
.fs30,
.fs30 span {
  font-size: 30px !important;
}
.fs40,
.fs40 span {
  font-size: 40px !important;
  line-height: 45px;
}
.fs50,
.fs50 span {
  font-size: 50px !important;
}
.bold {
  font-weight: 700 !important;
}
