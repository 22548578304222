.mt-Loading {
  &:after {
    background-color: rgba(0, 0, 0, 0.5);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  &--spinner {
    animation: rot 0.6s infinite linear;
    border-bottom: 4px solid rgba(255, 255, 255, 0.3);
    border-left: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 100%;
    border-right: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid rgba(255, 255, 255, 0.8);
    height: 50px;
    left: 50%;
    margin: -25px 0 0 -25px;
    position: absolute;
    top: 50%;
    width: 50px;
    z-index: 10;
  }
}

@keyframes rot {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
