/* Changes in PTA form before Cross-Pta implementation  */

#pg-Pta {
  // Hide PTA CTA in PTA page
  .mt-Topbar-ptaMobile {
    display: none;
  }

  .mt-OptionGroup {
    display: inline-flex;
    width: auto;

    label {
      font-family: $font;
      font-size: 14px;
      font-weight: normal;
      color: #606f80;
      padding: 7px;
      margin: 0;
    }
  }
  .mt-OptionGroup-input:checked + .mt-OptionGroup-label {
    background-color: #000e23;
    color: $c-white;
    font-weight: 600;
  }

  .mt-OptionGroup-item {
    min-width: 140px;
  }

  .pta-vertical-layout span.comentariosMessage {
    display: none;
  }

  .pta-form {
    max-width: 590px;
  }
  // Hide PTA button in PTA page in order to avoid users confusion
  .mt-Topbar-pta {
    display: none;
  }

  // Hide PTA quality rate layer MTR-16929
  #quality-ad {
    visibility: visible;
  }
}

@media only screen and (max-width: $mq-tablet - 1) {
  #pg-Pta {
    .pta-form {
      max-width: inherit;
    }

    .mt-OptionGroup {
      display: flex;
      width: 100%;
    }

    .mt-OptionGroup-item {
      min-width: auto;
    }

    .pta_info_adicional {
      min-width: auto;
    }
  }
}
