﻿// Better module box-sizing independence pattern
// https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  // font-feature-settings: 'ss02';
  margin: 0;
  padding: 0;
  text-align: left;
  background-color: #eef0f4;
}
a,
a:hover {
  color: $link-color;
  text-decoration: none;
}
a:hover:not(.btn) {
  color: $link-hover-color;
}
p a:hover {
  border-bottom: 1px solid;
  border-bottom-color: darken($link-hover-color, 10%);
}
a img {
  border: none;
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
strong,
b,
ul,
i,
span {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
