﻿// BOTONS

.btn {
  background-color: $red-color;
  border: 1px solid transparent;
  border-radius: 24px;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font: 600 17px/20px Coches, Verdana;
  margin: 0;
  outline: none;
  padding: 13px 55px;
  position: relative;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
  -webkit-appearance: none;

  &:hover:not(.btn-disabled) {
    background: $c-red-dark;
    text-decoration: none !important;
    color: #fff !important;
    border-color: transparent;
  }
  &:active:not(.btn-disabled):not(.btn-border-dark):not(.btn-border-light) {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    top: 1px;
    padding: 13px 55px 13px;
  }
  [class^='icon-'] {
    font-size: 17px;
    margin: 0 10px 0 0;
    vertical-align: middle;
  }
}
.btn-secondary {
  background-color: #000e23;
  &:hover:not(.btn-disabled) {
    background-color: #242f44;
  }
}
.btn-tertiary {
  background-color: $grey-color;
  &:hover:not(.btn-disabled) {
    background-color: lighten($grey-color, 5%);
  }
}
.btn-border-light {
  box-shadow: none;
  background-color: transparent;
  color: #fff !important;
  border-color: #fff;
  &:hover:not(.btn-disabled) {
    border-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 1);
    color: #333 !important;
  }
}
.btn-border-dark {
  box-shadow: none;
  background-color: white;
  color: #000e23 !important;
  border-color: #000e23;
  &:hover:not(.btn-disabled) {
    border-color: #000e23;
    background-color: #eef0f4;
    color: #000e23 !important;
  }
}
.btn-m {
  padding: 10px 25px;
  &:active:not(.btn-disabled):not(.btn-border-dark):not(.btn-border-light) {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    top: 1px;
    padding: 10px 25px 9px;
  }
}
.btn-s {
  padding: 3px 9px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
  &:active:not(.btn-disabled):not(.btn-border-dark):not(.btn-border-light) {
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.15);
    top: 1px;
    padding: 3px 9px 2px;
  }
  [class^='icon-'] {
    font-size: 14px;
    margin: 0 5px 0 0;
  }
}
.btn-icon {
  font-size: 12px;
  text-align: center;
  font-family: 'coches', Verdana, Arial;
  text-decoration: none;
  [class^='icon-'] {
    font-size: 25px;
    display: block;
    margin: 0 auto;
  }
  &:hover {
    border: none;
  }
}
.btn-disabled {
  @include opacity(0.6);
  cursor: default;
  box-shadow: none;
}
input.btn.block {
  width: 100%;
}
