﻿// DROPDOWN BUBBLE
// ----------------------------------------------------------------------------
.drop-bubble_wrap {
  position: relative;

  @media only screen and (min-width: $mq-tablet) {
    > a:before,
    > span:before {
      @include css-arrow($link-color);

      display: none;
      top: auto;
      bottom: 0;
    }
  }
}

.drop-bubble {
  background-color: $lightest-grey-color;
  border-top: 4px solid $link-color;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 100%;
  z-index: 10;

  @media only screen and (min-width: $mq-tablet) {
    display: none;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3), 0 0 0 2px #fff inset;
  }

  &_section {
    display: inline-block;
    margin-right: 12px;
    padding: 12px 4px;
    vertical-align: top;
    min-height: 105px;
    min-width: 160px;

    &:last-child {
      margin-right: 0;
    }

    .nav_btn {
      padding: 8px 12px;
    }
  }
  &_title {
    border-bottom: 1px solid #eee;
    color: #666;
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 12px;
    padding: 8px 12px;
    text-transform: uppercase;
  }
}

.drop-bubble--visible {
  > a:before,
  > span:before,
  .drop-bubble {
    display: block;
  }
}
