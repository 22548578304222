﻿//  Mobile First Method GRID SYSTEM

header .content {
  // margin-right:20px;
  // margin-left:20px;
}
.page {
  padding-top: 30px;
  max-width: 970px;
  width: 100%;
}
.side {
  width: 300px;
  float: right;
  display: none;
}
.grid {
  width: 100%;
  min-width: 0;
  margin-left: 0;
  margin-right: 0;
  padding-left: 20px; // grid-space to left
  padding-right: 10px; // grid-space to right: (grid-space-left - column-space) e.g. 20px-10px=10px
}
[class*='grid-'] {
  width: auto;
  float: none;
}

@media only screen and (min-width: 480px) {
  .row {
    margin-left: -1%;
    margin-right: -1%;
    zoom: 1;

    &:before,
    &:after {
      display: table;
      content: ' ';
    }
    &:after {
      clear: both;
    }
  }

  [class*='grid-'] {
    margin-left: 1%;
    margin-right: 1%;
    float: left;
  }
  .grid-1 {
    width: 6.333%;
  }

  .grid-2 {
    width: 14.667%;
  }

  .grid-3 {
    width: 23%;
  }

  .grid-4 {
    width: 31.333%;
  }

  .grid-5 {
    width: 39.667%;
  }

  .grid-6 {
    width: 48%;
  }

  .grid-7 {
    width: 56.333%;
  }

  .grid-8 {
    width: 64.667%;
  }

  .grid-9 {
    width: 73%;
  }

  .grid-10 {
    width: 81.333%;
  }

  .grid-11 {
    width: 89.667%;
  }

  .grid-12 {
    width: 98%;
  }
}

// Tablets and up
@media only screen and (min-width: $mq-tablet) {
  .page {
    padding-top: 0;
  }
}

// page(980) + (20 marginleft + 20 marginright) and up
@media only screen and (min-width: $mq-desktop) {
  .content {
    margin-top: 10px;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 970px;
  }
}

// content(page + side) + (20 marginleft + 20 marginright) and up
@media only screen and (min-width: $mq-wide) {
  .side {
    display: block;
    margin-left: 980px;
    position: fixed;
  }
  .content {
    max-width: 1280px;
  }
  .page {
    float: left;
  }
  .bannerless .content {
    max-width: 970px;
  }
  .bannerless .page {
    float: none;
  }
}
