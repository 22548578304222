.mt-UserArea {
  &-section {
    &Image {
      width: 220px;
    }
    @media screen and (min-width: $mq-tablet) {
      &Image {
        width: 270px;
      }
    }
  }
}
