.mt-error404 {
  background: $c-neutro-lightest;
  height: 100%;

  &-innerWrap {
    background-color: white;
    background-image: url(../img/error404-bg.png);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 70vh;
    height: 60vh;
    max-height: 430px;

    @media only screen and (min-width: $mq-tablet) {
      background-size: auto auto;
      height: 645px;
      max-height: none;
    }
  }
  &-sign {
    background-image: url(../img/error404-sign.png),
      url(../img/error404-cone.png), url(../img/error404-cone.png);
    background-position: center 80px, 25% bottom, 70% bottom;
    background-repeat: no-repeat;
    background-size: auto 350px, auto 50px, auto 50px;
    padding-top: 1.25rem;
    height: 60vh;
    max-height: 430px;

    @media only screen and (min-width: $mq-tablet) {
      background-position: center bottom, 25% bottom, 70% bottom;
      background-size: auto auto;
      height: 645px;
      max-height: none;
    }
  }
  &-logo {
    background-image: url(https://s.ccdn.es/images/coches/brand-refresh/logo-coches.svg);
    margin: 0 1.25rem;
    width: 210px;
    height: 34px;
  }
  &-message {
    padding: 1.25rem;

    @media only screen and (min-width: $mq-tablet) {
      padding: 11rem 2.5rem;
    }

    ul {
      margin-bottom: 1rem;
    }
  }
  &-title {
    font-size: 1.5rem;

    strong {
      display: block;
      font-size: 4.5rem;
      line-height: 5rem;
      margin-bottom: 0.5rem;
    }
  }
}
