﻿.pagination {
  line-height: 90px;
  height: 90px;
  text-align: center;
}
.pagination li {
  display: inline;
  margin: 0;
}
.pagination .pprev {
  float: left;
  margin-right: 3px;
}
.pagination .pnext {
  float: right;
  margin-left: 3px;
}
.pagination .pprev,
.pagination .pnext {
  padding: 5px 12px;
  margin-top: 26px;

  &:hover {
    color: #000;
  }
}
.pagination a {
  color: #000;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px 12px;
  cursor: pointer;
  text-decoration: none !important;
  font: 300 14pt coches, Verdana;

  &:hover {
    background-color: #f8f8f8;
  }
}
.pagination .selected {
  font: 600 14pt coches, Verdana;
  color: #e74c3c;
  padding: 5px 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f1f1f1;
}
