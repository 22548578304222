// MODAL ANIMATION
// ----------------------------------------------------------------------------
.anim-move-up-fade-in {
  animation-name: moveUpFadeIn;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.51, 0.92, 0.24, 1.15);
}
.anim-move-up-fade-out {
  animation-name: moveUpFadeOut;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.51, 0.92, 0.24, 1.15);
}

@keyframes moveUpFadeIn {
  0% {
    margin-top: 80px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes moveUpFadeOut {
  0% {
    margin-top: 0;
    opacity: 1;
  }
  100% {
    margin-top: 80px;
    opacity: 0;
  }
}
// MODAL ANIMATION LEGACY
// --------------------------------------------------------
@keyframes moveUpFadeInLegacy {
  0% {
    transform: translateY(80px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
