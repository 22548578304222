@font-face {
  font-family: 'motor-icons';
  src: url('/fonts/icomoon.eot');
  src: url('/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
    url('/fonts/icomoon.woff') format('woff'),
    url('/fonts/icomoon.ttf') format('truetype'),
    url('/fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^='icon-'],
[class*=' icon-'] {
  font-family: 'motor-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.4;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-closed-eye:before {
  content: '\e900';
}
.icon-alerta:before {
  content: '\e001';
}
.icon-android:before {
  content: '\e002';
}
.icon-anunci:before {
  content: '\e003';
}
.icon-apple:before {
  content: '\e004';
}
.icon-bell:before {
  content: '\e005';
}
.icon-borrar:before {
  content: '\e006';
}
.icon-calendari:before {
  content: '\e007';
}
.icon-candau-obert:before {
  content: '\e008';
}
.icon-candau:before {
  content: '\e009';
}
.icon-check:before {
  content: '\e00a';
}
.icon-coche:before {
  content: '\e00b';
}
.icon-comentaris:before {
  content: '\e00c';
}
.icon-contactar:before {
  content: '\e00d';
}
.icon-down:before {
  content: '\e00e';
}
.icon-dreta:before {
  content: '\e00f';
}
.icon-editar-perfil:before {
  content: '\e010';
}
.icon-editar:before {
  content: '\e011';
}
.icon-esquerra:before {
  content: '\e012';
}
.icon-estadistiques:before {
  content: '\e013';
}
.icon-estadistiques2:before {
  content: '\e014';
}
.icon-estrella2:before {
  content: '\e015';
}
.icon-facebook:before {
  content: '\e016';
}
.icon-fichas-tecnicas:before {
  content: '\e017';
}
.icon-filtros:before {
  content: '\e018';
}
.icon-fotos:before {
  content: '\e019';
}
.icon-fotos2:before {
  content: '\e01a';
}
.icon-fullscreen:before {
  content: '\e01b';
}
.icon-ghost:before {
  content: '\e01c';
}
.icon-google:before {
  content: '\e01d';
}
.icon-graella:before {
  content: '\e01e';
}
.icon-imprimir:before {
  content: '\e01f';
}
.icon-info:before {
  content: '\e020';
}
.icon-like:before {
  content: '\e021';
}
.icon-likeblack:before {
  content: '\e022';
}
.icon-link:before {
  content: '\e023';
}
.icon-location:before {
  content: '\e024';
}
.icon-loop:before {
  content: '\e025';
}
.icon-lupa:before {
  content: '\e026';
}
.icon-mail:before {
  content: '\e027';
}
.icon-mailetter:before {
  content: '\e028';
}
.icon-mailopen:before {
  content: '\e029';
}
.icon-menu:before {
  content: '\e02a';
}
.icon-nolike:before {
  content: '\e02b';
}
.icon-noticies:before {
  content: '\e02c';
}
.icon-ok:before {
  content: '\e02d';
}
.icon-ordenar:before {
  content: '\e02e';
}
.icon-play:before {
  content: '\e02f';
}
.icon-portatil:before {
  content: '\e030';
}
.icon-pregunta:before {
  content: '\e031';
}
.icon-premi:before {
  content: '\e032';
}
.icon-refresh:before {
  content: '\e033';
}
.icon-registre:before {
  content: '\e034';
}
.icon-reload:before {
  content: '\e035';
}
.icon-servicios:before {
  content: '\e036';
}
.icon-share:before {
  content: '\e037';
}
.icon-smartphone:before {
  content: '\e038';
}
.icon-suggest:before {
  content: '\e039';
}
.icon-suma:before {
  content: '\e03a';
}
.icon-tablet:before {
  content: '\e03b';
}
.icon-talk:before {
  content: '\e03c';
}
.icon-tancar:before {
  content: '\e03d';
}
.icon-tancar2:before {
  content: '\e03e';
}
.icon-telefon:before {
  content: '\e03f';
}
.icon-telefon2:before {
  content: '\e040';
}
.icon-time:before {
  content: '\e041';
}
.icon-tornar:before {
  content: '\e042';
}
.icon-twitter:before {
  content: '\e043';
}
.icon-up:before {
  content: '\e044';
}
.icon-user-following:before {
  content: '\e045';
}
.icon-user-unfollow:before {
  content: '\e046';
}
.icon-user:before {
  content: '\e047';
}
.icon-users:before {
  content: '\e048';
}
.icon-video:before {
  content: '\e049';
}
.icon-visites:before {
  content: '\e04a';
}
.icon-xarxes:before {
  content: '\e04b';
}
.icon-youtube:before {
  content: '\e04c';
}
