@mixin css-arrow($color: inherit, $size: 8px) {
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: $color;
  border-right-color: transparent;
  border-style: solid;
  border-width: 0 $size $size;
  content: '';
  display: block;
  margin-left: -($size);
  pointer-events: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 0;
  height: 0;
}

// Micro Clearfix by Nicolas Gallagher
// ------------------------------------------------------------
// http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix {
  *zoom: 1;

  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Image Replacement
// ------------------------------------------------------------
@mixin ir {
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  text-indent: 200%;
  overflow: hidden;
  white-space: nowrap;

  .lt-ie8 & {
    text-indent: -100%;
  }
}

@mixin u-hide--visually {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
