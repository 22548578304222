// PTA UPLOADER
// ----------------------------------------------------------------------------
.upldr {
  &-top {
    margin-bottom: 10px;
    min-height: 115px;
  }

  &-txt {
    background-image: url('../img/prev-img.png');
    background-position: center top;
    background-repeat: no-repeat;
    font-weight: 200;
    font-size: 18px;
    font-family: $font;
    padding-top: 144px;
    text-align: center;

    small {
      color: #666;
      font-size: 14px;
    }
  }

  &-bottom {
    text-align: center;
  }
}

.mt-PhotoUploader {
  box-sizing: border-box;
  background-color: #eef0f4;
  border-color: #bfc7d0;
  border-radius: 8px;
  border-style: dashed;
  border-width: 2px;
  padding: 20px 15px;
  transition: background linear 0.2s;

  .mt-modal & {
    border: 0;
  }

  .info-field {
    font-size: 15px;
    color: #999;
    padding-left: 0 !important;
  }

  &-item {
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.15);
    display: inline-block;
    margin: 0 0.25rem 1rem;
    position: relative;
    width: 130px;
  }

  &-thumb {
    cursor: pointer;
    outline-color: transparent;
    outline-offset: -2px;
    outline-style: solid;
    outline-width: 2px;
    overflow: hidden;
    position: relative;
    width: 130px;
    height: 130px;

    &:hover {
      outline-color: $c-neutro-dark;
    }

    img {
      display: block;
      transition: opacity 1s ease;
      width: 100%;
      height: 100%;
    }
  }

  // ACTIVE FOTO MSG
  // ------------------------------------
  &-tooltip {
    background: #000e23;
    color: #ffffff;
    display: none;
    font-size: 1em;
    line-height: 0.75rem;
    padding: 0.25rem;
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    // z-index: 20;
  }

  // PROGRESS BAR
  // ------------------------------------
  &-progressBar {
    background: #fff;
    border-radius: 0.5rem;
    display: none;
    padding: 2px;
    position: absolute;
    left: 0.25rem;
    right: 0.25rem;
    bottom: 0.5rem;
    height: 0.625rem;
  }

  &-progress {
    background: $c-green;
    border-radius: 0.5rem;
    height: 100%;
    width: 0;
  }

  &-icon {
    background-image: url('../img/sprite-upldr.png');
    background-repeat: no-repeat;
    margin: 0 auto;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 45px;

    &--ok {
      background-position: center -25px;
    }
    &--ko {
      // background-position: 0 -70px;
      background-position: center bottom;

      &::before {
        color: #ffffff;
        content: 'Reintentar';
        display: block;
        font-size: rem(14px);
        letter-spacing: 0.05em;
        margin-top: 0.25rem;
        position: absolute;
        top: 100%;
        left: 0;
        text-align: center;
        width: 100%;
      }
    }
    &--re {
    }
  }

  // DELETE BUTTON
  // ------------------------------------
  &-itemBtn {
    background-color: #ffffff;
    border: 0;
    color: #000e23;
    cursor: pointer;
    display: block;
    font-size: 1em;
    line-height: 0.75rem;
    padding: 0.5rem 0.25rem;
    transition: all 0.4s ease-out;
    width: 100%;

    [class^='icon-'] {
      font-size: 1em;
      vertical-align: middle;
      position: relative;
      top: -1px;
    }

    &:hover {
      background-color: $c-neutro-lightest;
    }
  }

  // STATES
  // --------------------------------------------------------
  &-item {
    &.pending {
      img {
        opacity: 0.4;
      }
    }

    &.uploading {
      .mt-PhotoUploader-progressBar {
        display: block;
      }
    }

    &.active {
      .mt-PhotoUploader-thumb {
        outline-color: #000e23;
      }

      .mt-PhotoUploader-tooltip {
        display: block;
      }
    }

    &.error {
      .mt-PhotoUploader-thumb {
        background-color: $c-red;
      }
      img {
        transition: all 0.4s ease-out;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=40)';
        opacity: 0.4;
      }
      &:hover {
        img {
          opacity: 0.2;
        }
      }
    }
  }
}

.nodisplay {
  display: none;
}

// .photo-wrapper {
//   &.active,
//   &:hover {
//     background-color: #fff;
//     border-color: #3598db;
//     cursor: pointer;
//   }
//   &:hover .btn {
//     background: #e72e00;
//   }
//   .photo-loader {
//     background: url('Images/ico-camera.png') center 0 no-repeat;
//     margin: 0 auto;
//     padding-top: 60px;
//     text-align: center;
//   }
// }

// .upldr {
//   &-imgs {
//     li {
//       .img {
//         border-radius: 3px;
//       }
//       // OCULTAR TODO
//       span.upldr-tooltp,
//       a.close,
//       .upldr-ico {
//         display: none;
//       }
//       // .upldr-barra,
//       &.active,
//       &.uploaded.active:hover {
//       }
//       &:before {
//         border: solid 2px transparent;
//         border-radius: 2px;
//         content: '';
//         display: block;
//         height: 100%;
//         position: absolute;
//         width: 100%;
//         z-index: 10;
//       }
//       &.active {
//         &::before {
//           border: solid 2px #fff;
//         }
//         span.upldr-tooltp {
//           &:before {
//             border-color: transparent transparent #3598db transparent;
//             border-style: solid;
//             border-width: 0 4px 5px 4px;
//             content: '';
//             display: block;
//             height: 0;
//             left: 50px;
//             position: absolute;
//             top: -5px;
//             width: 0;
//           }
//         }
//       }
//       &.uploaded {
//         &:hover {
//           border: solid #999 3px;
//           cursor: pointer;
//           transition: border-color linear 0.2s;
//           &::before {
//             border: solid 2px #fff;
//             border-radius: 2px;
//             content: '';
//             display: block;
//             height: 100%;
//             position: absolute;
//             width: 100%;
//           }
//         }
//         .img {
//           cursor: pointer;
//           -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
//           opacity: 1;
//           z-index: 0;
//         }
//       }
//       &.uploaded,
//       &.uploading,
//       &.pending {
//         a.close {
//           background: url('../img/sprite-upldr.png') no-repeat 0 0;
//           border: none;
//           display: block;
//           height: 25px;
//           overflow: hidden;
//           position: absolute;
//           right: -10px;
//           text-indent: -9999px;
//           top: -10px;
//           width: 25px;
//           z-index: 20;
//         }
//       }
//       &.pending,
//       &.uploading,
//       &.error {
//         .img {
//           opacity: 0.4;
//           z-index: 0;
//         }
//       }
//
//
//     }
//   }
// }
