.mt-Collapsible {
  margin-bottom: 15px;

  div,
  li,
  p {
    font-family: $font-open-sans;
    font-size: 14px;
  }

  h4 {
    color: #434b51;
  }

  &-description {
    font-size: 12px;
    padding: 15px 0;
  }

  &-section {
    color: #000e23;
    font-size: 14px;
    font-weight: bold;
    margin: 15px 0;
  }

  input {
    & + .mt-Collapsible-label {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      .mt-Collapsible-label-title {
        align-self: center;
        margin: 0;
      }
    }

    & ~ .mt-Collapsible-detail {
      display: none;
    }

    &:checked {
      & + .mt-Collapsible-label {
        &-arrow {
          transform: rotate(180deg);
        }
      }

      & ~ .mt-Collapsible-detail {
        display: block;
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
    padding: 0;

    &-item {
      margin-bottom: 15px;
      width: 45%;

      @media only screen and (max-width: $mq-tablet) {
        width: 100%;
      }

      &-no-checkbox {
        margin-left: 40px;
      }
    }

    &-title {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 8px;
      color: #434b51;
    }

    &-detail {
      color: #606f80;
      font-size: 10px;
      margin-bottom: 10px;
    }

    a {
      font-size: 14px;
      font-weight: 600;
      color: #000e23;
      text-decoration: underline;
    }
  }

  &-option {
    @media only screen and (min-width: $mq-tablet) {
      display: flex;
    }
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;

    @media only screen and (max-width: $mq-tablet) {
      padding-top: 10px;
    }

    &-box {
      @media only screen and (min-width: $mq-tablet) {
        display: flex;
      }
      justify-content: space-between;
      align-items: center;
      position: relative;

      .alert {
        display: none;
        flex-direction: column;
        font-size: 14px;
        height: 100%;
        padding: 20px;
        position: absolute;
        width: 100%;

        &.alert-show {
          display: inline-flex;
        }
      }
    }

    &-info {
      flex-basis: 50%;

      .mt-Collapsible-section {
        padding-bottom: 5px;
      }

      .mt-Collapsible-description {
        padding: 0;
      }
    }

    &-button {
      @media only screen and (max-width: $mq-tablet) {
        margin-top: 25px;
      }
      .mt-Button {
        font-size: 14px;
        width: 272px;
        @media only screen and (max-width: $mq-tablet) {
          width: 100%;
        }
      }
    }
  }
}
