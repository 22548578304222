// USER THUMBNAIL
// ----------------------------------------------------------------------------
.user-thumb {
  display: block;
  display: flex;
  align-items: center;
  overflow: hidden;

  &,
  &:visited {
    color: $darkest-grey-color;
  }

  &_avatar {
    border-radius: 50%;
    display: block;
    float: left;
    overflow: hidden;
    width: 36px;
    height: 36px;
    margin-right: 8px;
  }
  &_name {
    display: block;
    flex: 1 0 0;
    float: left;
    font-family: 'coches', arial, verdana;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;

    span {
      display: block;
    }
  }

  .topbar_main-nav &,
  .drop-bubble &,
  #nav-gta & {
    background-color: $darkest-grey-color;
    background-image: url('../img/user-bg.jpg');
    background-size: cover;
    padding: 8px 16px;
    position: relative;

    @media only screen and (max-width: $mq-tablet) {
      padding: 20px 16px;
    }
    &,
    &:visited,
    &:hover,
    &:focus {
      color: white;
    }
    > * {
      position: relative;
      z-index: 2;
    }
    &:before {
      background-color: rgba(255, 255, 255, 0);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 0.2s ease-in-out;
      z-index: 0;
    }
    &:hover,
    &:focus {
      &:before {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }
  .drop-bubble & {
    padding: 12px 20px;
  }
}
