﻿.alert {
  font-family: coches, Arial, Verdana;
  padding: 10px 10px 10px 45px;
  background: #fff;
  color: #555459;
  border-left-width: 5px;
  margin: 0 auto 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  position: relative;

  [class^='icon-'] {
    font-size: 22px;
    vertical-align: middle;
    margin-right: 10px;
    position: absolute;
    left: 10px;
    top: 7px;
  }
  .close-alert {
    position: absolute;
    right: 2px;
    top: 0px;
    left: auto;
    margin-right: 0;
    font-size: 16px;
  }
}
.alert-ok {
  border-left: 5px solid #27ae60;
  [class^='icon-'] {
    color: #27ae60;
  }
}
.alert-info {
  border-left: 5px solid #000e23;
  [class^='icon-'] {
    color: #000e23;
  }
}
.alert-warning {
  border-left: 5px solid #f39c12;
  [class^='icon-'] {
    color: #f39c12;
  }
}
.alert-error {
  border-left: 5px solid #e74c3c;
  background-color: #faf0f0;
  [class^='icon-'] {
    color: #e74c3c;
  }
}
