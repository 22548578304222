.mt-ProfilePhotoUpload {
  @include clearfix;
  border: 0;
  padding: 0.01em 0 0 1.5rem;
  margin: 0;
  min-width: 0;
  height: 100%;

  body:not(:-moz-handler-blocked) & {
    display: table-cell;
  }

  &-imgWrap {
    bottom: -1.5rem;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;

    @media screen and (max-width: $mq-tablet - 1) {
      &.drop-bubble--visible {
        .mt-ProfilePhotoUpload-img:after {
          background-color: #000000;
          border: 0;
          bottom: 0;
          content: '';
          display: block;
          height: auto;
          left: 0;
          opacity: 0.7;
          position: fixed;
          right: 0;
          top: 0;
          width: auto;
          z-index: 1;
        }
      }
    }
  }

  &-img {
    height: 80px;
    width: 80px;

    &--editable {
      &::before {
        background-color: #000e23;
        border-radius: 50%;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        color: #ffffff;
        content: '\E011';
        display: block;
        font-family: 'motor-icons';
        font-size: 1em;
        -webkit-font-smoothing: antialiased;
        font-style: normal;
        font-variant: normal;
        font-weight: 400;
        height: 1.5rem;
        line-height: 24px;
        position: absolute;
        right: 0;
        speak: none;
        text-align: center;
        text-transform: none;
        top: 0;
        width: 1.5rem;
        z-index: 9;
      }
    }

    img {
      display: block;
      border-radius: 50%;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
      width: 100%;
    }
  }

  &-controls {
    left: 50%;
    margin: 0;
    padding: 0;
    transform: translateX(-50%);
    z-index: 9;

    &::before {
      @include css-arrow($link-color);
      transform: translateY(-4px);
    }

    @media screen and (max-width: $mq-tablet - 1) {
      border: 0;
      display: none;
      padding: 0.5rem 0;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 70%;

      &:before {
        display: none;
      }
    }
  }

  &-label {
    background-color: transparent;
    border: 0;
    color: $c-neutro-darker !important;
    cursor: pointer;
    display: block;
    font-family: $f-coches !important;
    font-size: 1em !important;
    font-weight: 400 !important;
    line-height: 2rem !important;
    margin: 0 !important;
    padding: 0 1rem;
    text-align: left;
    white-space: nowrap;
    width: 100%;

    &:hover {
      color: $c-link !important;
    }
  }

  &-input {
    @include u-hide--visually;
  }

  &-title {
    color: #ffffff;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.25rem;
    vertical-align: bottom;

    p {
      margin-bottom: 0;
    }
  }

  .mt-Loading::after {
    border-radius: 50%;
  }
}
