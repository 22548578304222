// .mt-OptionGroup (drive 2)
// Styleguide Grid Components.mt-OptionGroup imported from drive 2

.mt-OptionGroup {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;

  .no-flexwrap & {
    & {
      *zoom: 1;
    }
    &::before,
    &::after {
      content: ' ';
      display: table;
    }
    &::after {
      clear: both;
    }
  }

  &-item {
    flex-basis: 2.25rem;
    flex-grow: 1;
    flex-shrink: 1;

    .no-flexwrap & {
      float: left;
    }
  }

  &-label {
    background-color: $c-white;
    border: 1px solid #cac6c3;
    border-radius: 0.25rem;
    cursor: pointer;
    display: block;
    font-family: $font;
    font-size: 0.875rem;
    line-height: 1.75rem;
    padding: 0.25rem 0.5rem;
    width: 100%;
    border-left-width: 0;
    border-radius: 0;
    height: 2.25rem;
    padding: 0.25rem 0;
    text-align: center;

    .mt-OptionGroup-item:first-child & {
      border-left: 1px solid $c-neutro-light;
      border-radius: 0.25rem 0 0 0.25rem;
    }

    .mt-OptionGroup-item:last-child & {
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }

  &-input {
    @include u-hide--visually;
  }

  &-input:focus + &-label {
    border-color: $c-gray-dark;
  }

  &-input:checked + &-label {
    background-color: $c-link-hover;
    color: $c-white;
  }
}
