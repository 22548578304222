﻿// Utility classes and mixins

// Alignment
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.clear {
  clear: both;
}

// Font weight
.fw-bold {
  font-weight: bold;
}

/* Clearfix */
.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.fleft {
  float: left;
}
.fright {
  float: right;
}
.overflow {
  overflow: hidden;
}

// Margins
.mgbot50 {
  margin-bottom: 50px !important;
}
.mgtop50 {
  margin-top: 50px !important;
}
.mgbot20 {
  margin-bottom: 20px !important;
}
.mgtop20 {
  margin-top: 20px !important;
}
.mgright20 {
  margin-right: 20px !important;
}
.mgleft20 {
  margin-left: 20px !important;
}
.mgbot10 {
  margin-bottom: 10px !important;
}
.mgbot5 {
  margin-bottom: 5px !important;
}
.mgtop10 {
  margin-top: 10px !important;
}
.mgright10 {
  margin-right: 10px !important;
}
.mgleft10 {
  margin-left: 10px !important;
}
body .mg0 {
  margin: 0 !important;
}
body .mgbot0 {
  margin-bottom: 0 !important;
}
body .mgtop0 {
  margin-top: 0 !important;
}
.mgauto {
  margin-left: auto;
  margin-right: auto;
}

// Padding
.pd20 {
  padding: 20px !important;
}
.pd10 {
  padding: 10px !important;
}
.pd0 {
  padding: 0 !important;
}

// Display
.hidden {
  display: none;
}
.hide {
  display: none !important;
}
.show,
.block {
  display: block;
}
.invisible {
  visibility: hidden;
}
.inline-block {
  display: inline-block;
}

// LLISTES
.list {
  list-style: none;

  li {
    line-height: 25px;
    font-family: coches, arial, verdana;
    font-size: 15px;
  }
}
.list.list-b li,
.list.list-rb li,
.list.list-bb li {
  padding-left: 1em;
  text-indent: -0.7em;
}
.list.list-b li:before,
.list.list-rb li:before,
.list.list-bb li:before {
  content: '• ';
  color: #999;
  position: relative;
  left: -5px;
  top: 2px;
  font-size: 22px;
}
.list.list-rb li:before {
  color: $red-color;
}
.list.list-bb li:before {
  color: $blue-color;
}

// VARIACIONS HEADERS
.line-b {
  border-bottom: 2px solid #dedede;
  padding-bottom: 10px;
}
.line-m {
  position: relative;
  span {
    background-color: #fff;
    position: relative;
    z-index: 20;
    padding-right: 20px;
  }
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #dedede;
    z-index: 10;
  }
}
.h-drop {
  position: relative;
}
.h-drop select {
  position: absolute;
  outline: none;
  bottom: 0;
  width: auto;
  right: -3px;
  font: 12pt Coches, Verdana;
  background-color: #fff;
  border: none;
  border-radius: 0;
  padding: 15px 30px 15px 0;
  color: #666;
  margin: 0;
  background: url(./../img/sprite-drive.png) no-repeat right 0 top -48px;
  height: 52px;
  border-bottom: 2px solid #666;
  line-height: 19px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: '';

  &:focus {
    box-shadow: none;
  }
}

.ani-color {
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.radius {
  border-radius: 3px;
}

@mixin opacity($value) {
  $IEValue: $value * 100;
  opacity: $value;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' + $IEValue +
    ')';
  filter: alpha(opacity=$IEValue);
}

// IMAGE REPLACEMENT
// ------------------------------------------------------------
.ir {
  @include ir;
}

// HIDE WHEN SOMETHING
// --------------------------------------------------------
.hide--when-no-logged {
  display: none;
  visibility: hidden;
}
.is--logged {
  .hide--when-logged {
    display: none;
    visibility: hidden;
  }
  .hide--when-no-logged {
    display: block;
    visibility: visible;
  }
}

@media only screen and (max-width: $mq-tablet - 1) {
  .hide--in-mobile {
    display: none !important;
    visibility: hidden !important;
  }
}
@media only screen and (min-width: $mq-tablet) {
  .hide--in-desktop {
    display: none !important;
    visibility: hidden !important;
  }
}

// FRONT AND BACK
// --------------------------------------------------------
@media only screen and (min-width: $mq-tablet) {
  .is--in-front {
    z-index: 100020 !important;
    position: relative !important;
  }
}
