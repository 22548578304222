.mt-InputCheckbox {
  cursor: pointer;
  display: block;
  position: relative;

  &-input {
    @include u-hide--visually;
  }

  &-label {
    display: block;
    line-height: 1.25rem;
    padding-left: 2.5rem;
    padding-top: 0.125rem;

    &::before {
      background-color: $c-white;
      border: 1px solid $c-gray-dark;
      border-radius: 0.25rem;
      content: '';
      display: block;
      height: 1.5rem;
      left: 0;
      position: absolute;
      top: 0;
      width: 1.5rem;
    }
  }

  &-input:checked + &-label::before {
    background: $c-link url(../img/check.svg) no-repeat center center;
    background-size: 70% auto;
    border-color: $c-link;
  }

  &--small {
    .mt-Checkbox-label {
      font-size: 12px;
      line-height: 1rem;
    }
  }

  &--icon {
    .mt-Checkbox-label {
      padding-left: 0;
      &::before {
        display: inline-block;
        position: inherit;
        vertical-align: middle;
      }
    }
    .mt-Icon {
      display: inline-block;
      vertical-align: middle;
      fill: #27ae60;
    }
  }

  .is--invalid & {
    &-label::before {
      border-color: $c-error;
    }
  }
}
