﻿// FORMS
// ----------------------------------------------------------------------------
.form {
  overflow: hidden;

  input[type='tel'],
  input[type='text'],
  input[type='email'],
  input[type='search'],
  input[type='number'],
  input[type='password'],
  textarea {
    background-color: #eef0f4;
    border: 1px solid #bfc7d0;
    border-radius: 3px;
    font-family: 'Coches', Verdana;
    font-size: 16px;
    margin-bottom: 20px;
    outline: none;
    padding: 0 15px 0 10px;
    width: 100%;
    height: 40px;
    color: #606f80;

    &:focus {
      border-color: $focus-color;
    }
    &.alert-field {
      border-color: $red-color !important;
      margin-bottom: 0.25rem;
    }
  }
  textarea {
    min-height: 100px;
    padding: 15px;
  }
  .dropdown.dropit {
    display: block;
  }
  .dropdown.dropit a {
    height: 38px;
    line-height: 26px;
    background-position: 99% -96px;
  }
  select {
    cursor: pointer;
    font: 14px 'Coches', Verdana;
    margin-bottom: 20px;
    height: 40px;
    background-color: #eef0f4;
    background-image: url(/img/sprite-drive.png);
    background-repeat: no-repeat;
    background-position: right 10px top -54px;
    border: 1px solid #bfc7d0;
    border-radius: 3px;
    color: #606f80;
    padding: 0 40px 0 10px;
    outline: none;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    text-indent: 0.01px;
    text-overflow: '';

    &:focus {
      border-color: $focus-color;
    }
    &[disabled] {
      background-color: #eef0f4;
      cursor: default;
      opacity: 0.6;
    }
  }
  select::-ms-expand {
    display: none;
  }
  label {
    display: block;
    font: 700 15px Coches, Verdana;
    color: #606f80;
    margin: 0 0 5px;
  }
  .icon-field {
    input,
    select {
      padding-left: 40px;
    }
    [class^='icon-'] {
      font-size: 20px;
      color: #ccc;
      margin: 5px 0 0 10px;
      position: absolute;
    }
  }
  .validated-field {
    background-image: url(/img/sprite-drive.png);
    background-position: right 10px top -423px;
    background-repeat: no-repeat;
  }

  input.alert-field {
    background-image: url(./../img/sprite-drive.png);
    background-position: right 10px top -279px;
    background-repeat: no-repeat;
  }
  .textarea_wrap {
    position: relative;

    span {
      display: none;
    }

    .alert-field + span {
      background-image: url(./../img/sprite-drive.png);
      background-position: 0 -289px;
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      width: 18px;
      height: 18px;
    }
  }
}
::-webkit-input-placeholder {
  color: #606f80;
}
:-moz-placeholder {
  color: #606f80;
}
::-moz-placeholder {
  color: #606f80;
}
:-ms-input-placeholder {
  color: #606f80;
}
// @-moz-document url-prefix() {
// 	.form select{background-image:none}
// }

/* Form rows (suitable to insert validation errors)*/
.form-row {
  margin-bottom: 20px;
}
.form-row input[type='text'],
.form-row input[type='email'],
.form-row input[type='search'],
.form-row input[type='number'],
.form-row input[type='password'],
.form-row select,
.form-row textarea {
  margin-bottom: 0;
}

label.suggest {
  color: #606f80;
}

.radio,
.checkbox {
  position: relative;
  display: block;
  min-height: 20px;

  label {
    padding-left: 20px;
    margin-bottom: 0;
    font: bold 15px/25px coches, Arial, sans-serif;
    color: #606f80;
    cursor: pointer;
    display: inline-block;
  }
  input {
    position: absolute;
    margin: 6px 0 0 -20px;
    line-height: normal;
    margin-top: 4px \9;
  }
}

// RADIO BUTTONS
// --------------------------------------------------------
.mt-radio {
  &__label {
    cursor: pointer;
    display: inline-block;
    font-family: $f-coches, Verdana, Arial;
    font-size: 16px;
    line-height: 24px;
    font-weight: 100;
    vertical-align: top;

    .mt-modal__body-section & {
      display: block;
      padding: 20px;
    }
  }
  [type='radio'] {
    display: inline-block;
    margin-right: 24px;
  }
}
