﻿// DRIVE 2.0 VARIABLES
// ----------------------------------------------------------------------------
$c-neutro-lightest: #fcfaf9;
$c-neutro-lighter: #f5f0ec;
$c-neutro-light: #e1dedc;
$c-neutro: #c7c3c0;
$c-neutro-dark: #999999;
$c-neutro-darker: #333333;
$c-neutro-darkest: #202020;

$c-red: #e60e27;
$c-red-dark: #b21229;
$c-blue: #4585f2;
$c-blue-dark: #3e77d9;
$c-green: #27ae60;
$c-yellow-lightest: #feffa4;
$c-yellow: #f39c12;
$c-gray-dark: #959290;
$c-gray-darker: #5f5e5d;
$c-gray-darkest: #202020;
$c-white: #ffffff;
$c-black: #000000;

$c-link: #000e23;
$c-link-hover: #242f44;

$c-body-text: $c-neutro-darkest;
$c-headings: $c-neutro-darkest;

$c-warning: $c-yellow;
$c-error: $c-red;
$c-success: $c-green;

$root-text-size: 14px;

// ----------------------------------------------------------------------------

$bg-topbar-user-brand: url(https://s.ccdn.es/images/logo-coches.svg) no-repeat
  50%;

$mq-mobile: 320px;
$mq-mobile-landscape: 480px;
$mq-tablet: 768px;
$mq-desktop: 970px;
$mq-wide: 1320px;

$f-coches: 'Poppins';
$font: $f-coches, Arial, Verdana;
$font-open-sans: 'Poppins', Arial, Verdana, sans-serif; // Poppins replaces Open Sans everywhere from now on

$white-color: $c-white;
$green-color: $c-green;
$orange-color: $c-yellow;
$red-color: $c-red;
$blue-color: #000e23;
$turquoise-color: #007a87;

$lightest-grey-color: $c-neutro-lightest;
$lighter-grey-color: $c-neutro-lighter;
$light-grey-color: $c-neutro-light;
$grey-color: $c-neutro;
$dark-grey-color: $c-neutro-dark;
$darker-grey-color: $c-neutro-darker;
$darkest-grey-color: $c-neutro-darkest;

$link-color: #000e23;
$link-hover-color: #242f44;

$focus-color: #bbb;

.white-color {
  color: $white-color !important;
}
.red-color {
  color: $red-color !important;
}
.blue-color {
  color: $blue-color !important;
}
.grey-color {
  color: $grey-color !important;
}
.light-grey-color {
  color: $light-grey-color !important;
}
.turquoise-color {
  color: $turquoise-color !important;
}
.orange-color {
  color: $orange-color !important;
}
.green-color {
  color: $green-color !important;
}

.red-bg {
  background-color: $red-color !important;
  color: #fff;
}
.blue-bg {
  background-color: $blue-color !important;
  color: #fff;
}
.grey-bg {
  background-color: $grey-color !important;
  color: #fff;
}
.light-grey-bg {
  background-color: $light-grey-color !important;
  color: #333;
}
.turquoise-bg {
  background-color: $turquoise-color !important;
  color: #fff;
}
.orange-bg {
  background-color: $orange-color !important;
  color: #fff;
}
.green-bg {
  background-color: $green-color !important;
  color: #fff;
}
