@charset "UTF-8";
.white-color {
  color: #ffffff !important; }

.red-color {
  color: #e60e27 !important; }

.blue-color {
  color: #000e23 !important; }

.grey-color {
  color: #c7c3c0 !important; }

.light-grey-color {
  color: #e1dedc !important; }

.turquoise-color {
  color: #007a87 !important; }

.orange-color {
  color: #f39c12 !important; }

.green-color {
  color: #27ae60 !important; }

.red-bg {
  background-color: #e60e27 !important;
  color: #fff; }

.blue-bg {
  background-color: #000e23 !important;
  color: #fff; }

.grey-bg {
  background-color: #c7c3c0 !important;
  color: #fff; }

.light-grey-bg {
  background-color: #e1dedc !important;
  color: #333; }

.turquoise-bg {
  background-color: #007a87 !important;
  color: #fff; }

.orange-bg {
  background-color: #f39c12 !important;
  color: #fff; }

.green-bg {
  background-color: #27ae60 !important;
  color: #fff; }

html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  text-align: left;
  background-color: #eef0f4; }

a,
a:hover {
  color: #000e23;
  text-decoration: none; }

a:hover:not(.btn) {
  color: #242f44; }

p a:hover {
  border-bottom: 1px solid;
  border-bottom-color: #121823; }

a img {
  border: none;
  vertical-align: top; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
strong,
b,
ul,
i,
span {
  margin: 0;
  padding: 0; }

ul {
  list-style: none; }

.center {
  text-align: center; }

.left {
  text-align: left; }

.right {
  text-align: right; }

.clear {
  clear: both; }

.fw-bold {
  font-weight: bold; }

/* Clearfix */
.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  *zoom: 1; }

.fleft {
  float: left; }

.fright {
  float: right; }

.overflow {
  overflow: hidden; }

.mgbot50 {
  margin-bottom: 50px !important; }

.mgtop50 {
  margin-top: 50px !important; }

.mgbot20 {
  margin-bottom: 20px !important; }

.mgtop20 {
  margin-top: 20px !important; }

.mgright20 {
  margin-right: 20px !important; }

.mgleft20 {
  margin-left: 20px !important; }

.mgbot10 {
  margin-bottom: 10px !important; }

.mgbot5 {
  margin-bottom: 5px !important; }

.mgtop10 {
  margin-top: 10px !important; }

.mgright10 {
  margin-right: 10px !important; }

.mgleft10 {
  margin-left: 10px !important; }

body .mg0 {
  margin: 0 !important; }

body .mgbot0 {
  margin-bottom: 0 !important; }

body .mgtop0 {
  margin-top: 0 !important; }

.mgauto {
  margin-left: auto;
  margin-right: auto; }

.pd20 {
  padding: 20px !important; }

.pd10 {
  padding: 10px !important; }

.pd0 {
  padding: 0 !important; }

.hidden {
  display: none; }

.hide {
  display: none !important; }

.show,
.block {
  display: block; }

.invisible {
  visibility: hidden; }

.inline-block {
  display: inline-block; }

.list {
  list-style: none; }
  .list li {
    line-height: 25px;
    font-family: coches, arial, verdana;
    font-size: 15px; }

.list.list-b li,
.list.list-rb li,
.list.list-bb li {
  padding-left: 1em;
  text-indent: -0.7em; }

.list.list-b li:before,
.list.list-rb li:before,
.list.list-bb li:before {
  content: '• ';
  color: #999;
  position: relative;
  left: -5px;
  top: 2px;
  font-size: 22px; }

.list.list-rb li:before {
  color: #e60e27; }

.list.list-bb li:before {
  color: #000e23; }

.line-b {
  border-bottom: 2px solid #dedede;
  padding-bottom: 10px; }

.line-m {
  position: relative; }
  .line-m span {
    background-color: #fff;
    position: relative;
    z-index: 20;
    padding-right: 20px; }
  .line-m:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #dedede;
    z-index: 10; }

.h-drop {
  position: relative; }

.h-drop select {
  position: absolute;
  outline: none;
  bottom: 0;
  width: auto;
  right: -3px;
  font: 12pt Coches, Verdana;
  background-color: #fff;
  border: none;
  border-radius: 0;
  padding: 15px 30px 15px 0;
  color: #666;
  margin: 0;
  background: url(./../img/sprite-drive.png) no-repeat right 0 top -48px;
  height: 52px;
  border-bottom: 2px solid #666;
  line-height: 19px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: ''; }
  .h-drop select:focus {
    box-shadow: none; }

.ani-color {
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out; }

.radius {
  border-radius: 3px; }

.ir {
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  text-indent: 200%;
  overflow: hidden;
  white-space: nowrap; }
  .lt-ie8 .ir {
    text-indent: -100%; }

.hide--when-no-logged {
  display: none;
  visibility: hidden; }

.is--logged .hide--when-logged {
  display: none;
  visibility: hidden; }

.is--logged .hide--when-no-logged {
  display: block;
  visibility: visible; }

@media only screen and (max-width: 767px) {
  .hide--in-mobile {
    display: none !important;
    visibility: hidden !important; } }

@media only screen and (min-width: 768px) {
  .hide--in-desktop {
    display: none !important;
    visibility: hidden !important; } }

@media only screen and (min-width: 768px) {
  .is--in-front {
    z-index: 100020 !important;
    position: relative !important; } }

.anim-move-up-fade-in {
  animation-name: moveUpFadeIn;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.51, 0.92, 0.24, 1.15); }

.anim-move-up-fade-out {
  animation-name: moveUpFadeOut;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.51, 0.92, 0.24, 1.15); }

@keyframes moveUpFadeIn {
  0% {
    margin-top: 80px;
    opacity: 0; }
  100% {
    margin-top: 0;
    opacity: 1; } }

@keyframes moveUpFadeOut {
  0% {
    margin-top: 0;
    opacity: 1; }
  100% {
    margin-top: 80px;
    opacity: 0; } }

@keyframes moveUpFadeInLegacy {
  0% {
    transform: translateY(80px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

.page {
  padding-top: 30px;
  max-width: 970px;
  width: 100%; }

.side {
  width: 300px;
  float: right;
  display: none; }

.grid {
  width: 100%;
  min-width: 0;
  margin-left: 0;
  margin-right: 0;
  padding-left: 20px;
  padding-right: 10px; }

[class*='grid-'] {
  width: auto;
  float: none; }

@media only screen and (min-width: 480px) {
  .row {
    margin-left: -1%;
    margin-right: -1%;
    zoom: 1; }
    .row:before, .row:after {
      display: table;
      content: ' '; }
    .row:after {
      clear: both; }
  [class*='grid-'] {
    margin-left: 1%;
    margin-right: 1%;
    float: left; }
  .grid-1 {
    width: 6.333%; }
  .grid-2 {
    width: 14.667%; }
  .grid-3 {
    width: 23%; }
  .grid-4 {
    width: 31.333%; }
  .grid-5 {
    width: 39.667%; }
  .grid-6 {
    width: 48%; }
  .grid-7 {
    width: 56.333%; }
  .grid-8 {
    width: 64.667%; }
  .grid-9 {
    width: 73%; }
  .grid-10 {
    width: 81.333%; }
  .grid-11 {
    width: 89.667%; }
  .grid-12 {
    width: 98%; } }

@media only screen and (min-width: 768px) {
  .page {
    padding-top: 0; } }

@media only screen and (min-width: 970px) {
  .content {
    margin-top: 10px;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 970px; } }

@media only screen and (min-width: 1320px) {
  .side {
    display: block;
    margin-left: 980px;
    position: fixed; }
  .content {
    max-width: 1280px; }
  .page {
    float: left; }
  .bannerless .content {
    max-width: 970px; }
  .bannerless .page {
    float: none; } }

@font-face {
  font-family: 'coches';
  font-style: normal;
  font-weight: 400;
  src: url("https://s.ccdn.es/fonts/PoppinsMotor-400.otf") format("opentype"); }

@font-face {
  font-family: 'coches';
  font-style: normal;
  font-weight: 600;
  src: url("https://s.ccdn.es/fonts/PoppinsMotor-600.otf") format("opentype"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url("https://s.ccdn.es/fonts/PoppinsMotor-400.otf") format("opentype"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url("https://s.ccdn.es/fonts/PoppinsMotor-600.otf") format("opentype"); }

div,
li,
p {
  font-family: coches, arial, verdana;
  font-size: 16px;
  line-height: 1.45; }

b,
strong,
span {
  line-height: 20px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: coches, arial, verdana;
  font-weight: 300;
  color: #606f80;
  padding: 0;
  margin: 0 0 20px 0;
  line-height: 1.1; }

h1 {
  font-size: 30px; }

h2 {
  font-size: 26px; }

h3 {
  font-size: 22px; }

h4 {
  font-size: 18px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 18px; }

p {
  margin: 0 0 10px; }

.font,
.font span,
.font p,
.font b {
  font-family: coches, arial; }

.font,
.font span,
.font p {
  font-weight: 300; }

.font b,
.font strong {
  font-weight: 600; }

.fs12,
.fs12 span {
  font-size: 12px !important; }

.fs16,
.fs16 span {
  font-size: 16px !important; }

.fs20,
.fs20 span {
  font-size: 20px !important; }

.fs25,
.fs25 span {
  font-size: 25px !important; }

.fs30,
.fs30 span {
  font-size: 30px !important; }

.fs40,
.fs40 span {
  font-size: 40px !important;
  line-height: 45px; }

.fs50,
.fs50 span {
  font-size: 50px !important; }

.bold {
  font-weight: 700 !important; }

.btn {
  background-color: #e60e27;
  border: 1px solid transparent;
  border-radius: 24px;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font: 600 17px/20px Coches, Verdana;
  margin: 0;
  outline: none;
  padding: 13px 55px;
  position: relative;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
  -webkit-appearance: none; }
  .btn:hover:not(.btn-disabled) {
    background: #b21229;
    text-decoration: none !important;
    color: #fff !important;
    border-color: transparent; }
  .btn:active:not(.btn-disabled):not(.btn-border-dark):not(.btn-border-light) {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    top: 1px;
    padding: 13px 55px 13px; }
  .btn [class^='icon-'] {
    font-size: 17px;
    margin: 0 10px 0 0;
    vertical-align: middle; }

.btn-secondary {
  background-color: #000e23; }
  .btn-secondary:hover:not(.btn-disabled) {
    background-color: #242f44; }

.btn-tertiary {
  background-color: #c7c3c0; }
  .btn-tertiary:hover:not(.btn-disabled) {
    background-color: #d3d0ce; }

.btn-border-light {
  box-shadow: none;
  background-color: transparent;
  color: #fff !important;
  border-color: #fff; }
  .btn-border-light:hover:not(.btn-disabled) {
    border-color: white;
    background-color: white;
    color: #333 !important; }

.btn-border-dark {
  box-shadow: none;
  background-color: white;
  color: #000e23 !important;
  border-color: #000e23; }
  .btn-border-dark:hover:not(.btn-disabled) {
    border-color: #000e23;
    background-color: #eef0f4;
    color: #000e23 !important; }

.btn-m {
  padding: 10px 25px; }
  .btn-m:active:not(.btn-disabled):not(.btn-border-dark):not(.btn-border-light) {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    top: 1px;
    padding: 10px 25px 9px; }

.btn-s {
  padding: 3px 9px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px; }
  .btn-s:active:not(.btn-disabled):not(.btn-border-dark):not(.btn-border-light) {
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.15);
    top: 1px;
    padding: 3px 9px 2px; }
  .btn-s [class^='icon-'] {
    font-size: 14px;
    margin: 0 5px 0 0; }

.btn-icon {
  font-size: 12px;
  text-align: center;
  font-family: 'coches', Verdana, Arial;
  text-decoration: none; }
  .btn-icon [class^='icon-'] {
    font-size: 25px;
    display: block;
    margin: 0 auto; }
  .btn-icon:hover {
    border: none; }

.btn-disabled {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
  cursor: default;
  box-shadow: none; }

input.btn.block {
  width: 100%; }

.form {
  overflow: hidden; }
  .form input[type='tel'],
  .form input[type='text'],
  .form input[type='email'],
  .form input[type='search'],
  .form input[type='number'],
  .form input[type='password'],
  .form textarea {
    background-color: #eef0f4;
    border: 1px solid #bfc7d0;
    border-radius: 3px;
    font-family: 'Coches', Verdana;
    font-size: 16px;
    margin-bottom: 20px;
    outline: none;
    padding: 0 15px 0 10px;
    width: 100%;
    height: 40px;
    color: #606f80; }
    .form input[type='tel']:focus,
    .form input[type='text']:focus,
    .form input[type='email']:focus,
    .form input[type='search']:focus,
    .form input[type='number']:focus,
    .form input[type='password']:focus,
    .form textarea:focus {
      border-color: #bbb; }
    .form input[type='tel'].alert-field,
    .form input[type='text'].alert-field,
    .form input[type='email'].alert-field,
    .form input[type='search'].alert-field,
    .form input[type='number'].alert-field,
    .form input[type='password'].alert-field,
    .form textarea.alert-field {
      border-color: #e60e27 !important;
      margin-bottom: 0.25rem; }
  .form textarea {
    min-height: 100px;
    padding: 15px; }
  .form .dropdown.dropit {
    display: block; }
  .form .dropdown.dropit a {
    height: 38px;
    line-height: 26px;
    background-position: 99% -96px; }
  .form select {
    cursor: pointer;
    font: 14px 'Coches', Verdana;
    margin-bottom: 20px;
    height: 40px;
    background-color: #eef0f4;
    background-image: url(/img/sprite-drive.png);
    background-repeat: no-repeat;
    background-position: right 10px top -54px;
    border: 1px solid #bfc7d0;
    border-radius: 3px;
    color: #606f80;
    padding: 0 40px 0 10px;
    outline: none;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    text-indent: 0.01px;
    text-overflow: ''; }
    .form select:focus {
      border-color: #bbb; }
    .form select[disabled] {
      background-color: #eef0f4;
      cursor: default;
      opacity: 0.6; }
  .form select::-ms-expand {
    display: none; }
  .form label {
    display: block;
    font: 700 15px Coches, Verdana;
    color: #606f80;
    margin: 0 0 5px; }
  .form .icon-field input,
  .form .icon-field select {
    padding-left: 40px; }
  .form .icon-field [class^='icon-'] {
    font-size: 20px;
    color: #ccc;
    margin: 5px 0 0 10px;
    position: absolute; }
  .form .validated-field {
    background-image: url(/img/sprite-drive.png);
    background-position: right 10px top -423px;
    background-repeat: no-repeat; }
  .form input.alert-field {
    background-image: url(./../img/sprite-drive.png);
    background-position: right 10px top -279px;
    background-repeat: no-repeat; }
  .form .textarea_wrap {
    position: relative; }
    .form .textarea_wrap span {
      display: none; }
    .form .textarea_wrap .alert-field + span {
      background-image: url(./../img/sprite-drive.png);
      background-position: 0 -289px;
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      width: 18px;
      height: 18px; }

::-webkit-input-placeholder {
  color: #606f80; }

:-moz-placeholder {
  color: #606f80; }

::-moz-placeholder {
  color: #606f80; }

:-ms-input-placeholder {
  color: #606f80; }

/* Form rows (suitable to insert validation errors)*/
.form-row {
  margin-bottom: 20px; }

.form-row input[type='text'],
.form-row input[type='email'],
.form-row input[type='search'],
.form-row input[type='number'],
.form-row input[type='password'],
.form-row select,
.form-row textarea {
  margin-bottom: 0; }

label.suggest {
  color: #606f80; }

.radio,
.checkbox {
  position: relative;
  display: block;
  min-height: 20px; }
  .radio label,
  .checkbox label {
    padding-left: 20px;
    margin-bottom: 0;
    font: bold 15px/25px coches, Arial, sans-serif;
    color: #606f80;
    cursor: pointer;
    display: inline-block; }
  .radio input,
  .checkbox input {
    position: absolute;
    margin: 6px 0 0 -20px;
    line-height: normal;
    margin-top: 4px \9; }

.mt-radio__label {
  cursor: pointer;
  display: inline-block;
  font-family: "Poppins", Verdana, Arial;
  font-size: 16px;
  line-height: 24px;
  font-weight: 100;
  vertical-align: top; }
  .mt-modal__body-section .mt-radio__label, .content-dialog .mt-radio__label {
    display: block;
    padding: 20px; }

.mt-radio [type='radio'] {
  display: inline-block;
  margin-right: 24px; }

.table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #ddd;
  font-family: Coches, Verdana; }
  .table td,
  .table th {
    font-size: 17px;
    margin: 0;
    overflow: visible;
    padding: 0.7em 1em;
    border-width: 0 0 1px;
    border-bottom: 1px solid #ddd; }
  .table thead {
    background: #e0e0e0;
    color: #000;
    text-align: left;
    vertical-align: bottom; }

.table td {
  background-color: transparent; }

.table-zebra tbody tr:nth-child(odd) {
  background-color: #f5f5f5; }

.table-zebra td,
.table-zebra th {
  border-bottom: 1px solid transparent; }

.table-zebra tbody > tr:last-child td {
  border-bottom: 1px solid #cbcbcb; }

.boxed {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px; }

.box-header {
  border: 1px solid #dedede;
  padding: 15px 20px 20px 20px;
  margin-bottom: 20px; }

.box-header .title-box {
  color: #333;
  background-color: #eee;
  margin: -15px -20px 15px;
  padding: 10px 20px; }

.drop-bubble_wrap {
  position: relative; }
  @media only screen and (min-width: 768px) {
    .drop-bubble_wrap > a:before,
    .drop-bubble_wrap > span:before {
      border-top-color: transparent;
      border-left-color: transparent;
      border-bottom-color: #000e23;
      border-right-color: transparent;
      border-style: solid;
      border-width: 0 8px 8px;
      content: '';
      display: block;
      margin-left: -8px;
      pointer-events: none;
      position: absolute;
      bottom: 100%;
      left: 50%;
      width: 0;
      height: 0;
      display: none;
      top: auto;
      bottom: 0; } }

.drop-bubble {
  background-color: #fcfaf9;
  border-top: 4px solid #000e23;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 100%;
  z-index: 10; }
  @media only screen and (min-width: 768px) {
    .drop-bubble {
      display: none;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3), 0 0 0 2px #fff inset; } }
  .drop-bubble_section {
    display: inline-block;
    margin-right: 12px;
    padding: 12px 4px;
    vertical-align: top;
    min-height: 105px;
    min-width: 160px; }
    .drop-bubble_section:last-child {
      margin-right: 0; }
    .drop-bubble_section .nav_btn {
      padding: 8px 12px; }
  .drop-bubble_title {
    border-bottom: 1px solid #eee;
    color: #666;
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 12px;
    padding: 8px 12px;
    text-transform: uppercase; }

.drop-bubble--visible > a:before,
.drop-bubble--visible > span:before,
.drop-bubble--visible .drop-bubble {
  display: block; }

.pagination {
  line-height: 90px;
  height: 90px;
  text-align: center; }

.pagination li {
  display: inline;
  margin: 0; }

.pagination .pprev {
  float: left;
  margin-right: 3px; }

.pagination .pnext {
  float: right;
  margin-left: 3px; }

.pagination .pprev,
.pagination .pnext {
  padding: 5px 12px;
  margin-top: 26px; }
  .pagination .pprev:hover,
  .pagination .pnext:hover {
    color: #000; }

.pagination a {
  color: #000;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px 12px;
  cursor: pointer;
  text-decoration: none !important;
  font: 300 14pt coches, Verdana; }
  .pagination a:hover {
    background-color: #f8f8f8; }

.pagination .selected {
  font: 600 14pt coches, Verdana;
  color: #e74c3c;
  padding: 5px 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f1f1f1; }

.nav_btn {
  display: block;
  background-color: #fff;
  font-family: "Poppins", Arial, Verdana, arial, verdana;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  padding: 12px 20px;
  text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .nav_btn {
      font-size: 16px; } }
  .nav_btn [class^='icon-'] {
    color: #434b51;
    display: inline-block;
    font-size: 20px;
    line-height: 24px;
    margin-right: 32px; }
    @media only screen and (min-width: 768px) {
      .nav_btn [class^='icon-'] {
        margin-right: 20px; } }
  .nav_btn, .nav_btn:visited {
    color: #434b51; }
  .nav_btn:hover,
  .nav_btn:hover [class^='icon-'], .nav_btn:focus,
  .nav_btn:focus [class^='icon-'] {
    color: #242f44; }
  .nav_btn.is--active {
    background: white;
    font-weight: 600; }
  .nav_btn.has--extra-padding {
    padding-top: 20px; }
    @media only screen and (min-width: 768px) {
      .nav_btn.has--extra-padding {
        padding-top: 12px; } }
  .nav_btn.has--border-top {
    border-top: 1px solid #e0dfdd; }
  .nav_btn.has--border-bottom {
    border-bottom: 1px solid #e0dfdd; }
  @media only screen and (max-width: 767px) {
    .nav_btn--blue {
      color: #000e23; } }

@media only screen and (max-width: 767px) {
  .nav-slide {
    position: absolute;
    top: 0;
    bottom: 100%;
    left: 0;
    right: 0;
    transition: all 0.1s 0.4s linear;
    z-index: 2001; }
    .nav-slide_open-btn {
      display: block; }
      .nav-slide_open-btn .icon-menu {
        color: #e60e27;
        display: block;
        font-size: 24px;
        line-height: 24px;
        font-weight: 600; }
    .nav-slide_mask {
      background-color: #000;
      cursor: pointer;
      opacity: 0;
      position: fixed;
      top: 0;
      bottom: 0;
      left: -100%;
      transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      width: 100%;
      z-index: -1; }
    .nav-slide_content {
      background-color: #fcfaf9;
      background-size: cover;
      overflow: auto;
      padding-top: 64px;
      padding-bottom: 16px;
      position: absolute;
      top: 0;
      bottom: 0;
      transform: translateX(-100%);
      width: 290px;
      transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1); }
  .is--nav-slide-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%; }
    .is--nav-slide-open .nav-slide {
      bottom: 0;
      transition: all 0.1s linear; }
      .is--nav-slide-open .nav-slide_mask {
        opacity: 0.6;
        left: 0;
        transition: opacity 0.4s 0.1s cubic-bezier(0.4, 0, 0.2, 1);
        z-index: 0; }
      .is--nav-slide-open .nav-slide_content {
        transform: translateX(0);
        transition: transform 0.4s 0.1s cubic-bezier(0.4, 0, 0.2, 1); } }

.side-nav span {
  font-size: 15px;
  font-weight: 700;
  display: block;
  margin: 20px 0 5px; }

.side-nav > li a {
  color: #666;
  display: block;
  padding: 5px 15px 5px 25px; }
  .side-nav > li a:hover {
    box-shadow: 1px 0 0 #ccc inset; }
  .side-nav > li a.active {
    box-shadow: 5px 0 0 #000e23 inset !important;
    color: #000e23 !important; }

.side-nav ul a {
  font: 14px/15px Arial, Verdana;
  line-height: 15px;
  padding-left: 15px; }
  .side-nav ul a.active {
    box-shadow: 1px 0 0 #000e23 inset !important; }

.nav > ul {
  display: none;
  height: auto; }

.nav li {
  display: block;
  float: none;
  width: 100%; }

.nav a {
  color: #333;
  display: block;
  font-family: 'coches', Verdana, Arial;
  font-size: 15px;
  line-height: 60px; }
  .nav a:hover {
    color: #000; }
  .nav a.active {
    box-shadow: inset 0 -5px 0 #e7e7e7;
    color: #000; }

.nav span {
  font-size: 24px;
  color: #e60e27;
  font-weight: bold;
  margin: 13px 0 0 5px;
  display: inline-block; }

@media only screen and (min-width: 768px) {
  .nav > ul {
    display: block; }
  .nav li {
    display: inline-block;
    width: auto; }
  .nav ul a [class^='icon-'] {
    display: none; }
  .nav .pull {
    display: none; } }

.alert {
  font-family: coches, Arial, Verdana;
  padding: 10px 10px 10px 45px;
  background: #fff;
  color: #555459;
  border-left-width: 5px;
  margin: 0 auto 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  position: relative; }
  .alert [class^='icon-'] {
    font-size: 22px;
    vertical-align: middle;
    margin-right: 10px;
    position: absolute;
    left: 10px;
    top: 7px; }
  .alert .close-alert {
    position: absolute;
    right: 2px;
    top: 0px;
    left: auto;
    margin-right: 0;
    font-size: 16px; }

.alert-ok {
  border-left: 5px solid #27ae60; }
  .alert-ok [class^='icon-'] {
    color: #27ae60; }

.alert-info {
  border-left: 5px solid #000e23; }
  .alert-info [class^='icon-'] {
    color: #000e23; }

.alert-warning {
  border-left: 5px solid #f39c12; }
  .alert-warning [class^='icon-'] {
    color: #f39c12; }

.alert-error {
  border-left: 5px solid #e74c3c;
  background-color: #faf0f0; }
  .alert-error [class^='icon-'] {
    color: #e74c3c; }

@font-face {
  font-family: 'motor-icons';
  src: url("/fonts/icomoon.eot");
  src: url("/fonts/icomoon.eot?#iefix") format("embedded-opentype"), url("/fonts/icomoon.woff") format("woff"), url("/fonts/icomoon.ttf") format("truetype"), url("/fonts/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'motor-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.4;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-closed-eye:before {
  content: '\e900'; }

.icon-alerta:before {
  content: '\e001'; }

.icon-android:before {
  content: '\e002'; }

.icon-anunci:before {
  content: '\e003'; }

.icon-apple:before {
  content: '\e004'; }

.icon-bell:before {
  content: '\e005'; }

.icon-borrar:before {
  content: '\e006'; }

.icon-calendari:before {
  content: '\e007'; }

.icon-candau-obert:before {
  content: '\e008'; }

.icon-candau:before {
  content: '\e009'; }

.icon-check:before {
  content: '\e00a'; }

.icon-coche:before {
  content: '\e00b'; }

.icon-comentaris:before {
  content: '\e00c'; }

.icon-contactar:before {
  content: '\e00d'; }

.icon-down:before {
  content: '\e00e'; }

.icon-dreta:before {
  content: '\e00f'; }

.icon-editar-perfil:before {
  content: '\e010'; }

.icon-editar:before {
  content: '\e011'; }

.icon-esquerra:before {
  content: '\e012'; }

.icon-estadistiques:before {
  content: '\e013'; }

.icon-estadistiques2:before {
  content: '\e014'; }

.icon-estrella2:before {
  content: '\e015'; }

.icon-facebook:before {
  content: '\e016'; }

.icon-fichas-tecnicas:before {
  content: '\e017'; }

.icon-filtros:before {
  content: '\e018'; }

.icon-fotos:before {
  content: '\e019'; }

.icon-fotos2:before {
  content: '\e01a'; }

.icon-fullscreen:before {
  content: '\e01b'; }

.icon-ghost:before {
  content: '\e01c'; }

.icon-google:before {
  content: '\e01d'; }

.icon-graella:before {
  content: '\e01e'; }

.icon-imprimir:before {
  content: '\e01f'; }

.icon-info:before {
  content: '\e020'; }

.icon-like:before {
  content: '\e021'; }

.icon-likeblack:before {
  content: '\e022'; }

.icon-link:before {
  content: '\e023'; }

.icon-location:before {
  content: '\e024'; }

.icon-loop:before {
  content: '\e025'; }

.icon-lupa:before {
  content: '\e026'; }

.icon-mail:before {
  content: '\e027'; }

.icon-mailetter:before {
  content: '\e028'; }

.icon-mailopen:before {
  content: '\e029'; }

.icon-menu:before {
  content: '\e02a'; }

.icon-nolike:before {
  content: '\e02b'; }

.icon-noticies:before {
  content: '\e02c'; }

.icon-ok:before {
  content: '\e02d'; }

.icon-ordenar:before {
  content: '\e02e'; }

.icon-play:before {
  content: '\e02f'; }

.icon-portatil:before {
  content: '\e030'; }

.icon-pregunta:before {
  content: '\e031'; }

.icon-premi:before {
  content: '\e032'; }

.icon-refresh:before {
  content: '\e033'; }

.icon-registre:before {
  content: '\e034'; }

.icon-reload:before {
  content: '\e035'; }

.icon-servicios:before {
  content: '\e036'; }

.icon-share:before {
  content: '\e037'; }

.icon-smartphone:before {
  content: '\e038'; }

.icon-suggest:before {
  content: '\e039'; }

.icon-suma:before {
  content: '\e03a'; }

.icon-tablet:before {
  content: '\e03b'; }

.icon-talk:before {
  content: '\e03c'; }

.icon-tancar:before {
  content: '\e03d'; }

.icon-tancar2:before {
  content: '\e03e'; }

.icon-telefon:before {
  content: '\e03f'; }

.icon-telefon2:before {
  content: '\e040'; }

.icon-time:before {
  content: '\e041'; }

.icon-tornar:before {
  content: '\e042'; }

.icon-twitter:before {
  content: '\e043'; }

.icon-up:before {
  content: '\e044'; }

.icon-user-following:before {
  content: '\e045'; }

.icon-user-unfollow:before {
  content: '\e046'; }

.icon-user:before {
  content: '\e047'; }

.icon-users:before {
  content: '\e048'; }

.icon-video:before {
  content: '\e049'; }

.icon-visites:before {
  content: '\e04a'; }

.icon-xarxes:before {
  content: '\e04b'; }

.icon-youtube:before {
  content: '\e04c'; }

@media only screen and (max-width: 480px) {
  .is--modal-open {
    overflow: hidden;
    width: 100%; } }

.mt-modal, .modal-dialog {
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  display: none;
  -webkit-animation: moveUpFadeIn 0.3s cubic-bezier(0.51, 0.92, 0.24, 1.15);
  animation: moveUpFadeIn 0.3s cubic-bezier(0.51, 0.92, 0.24, 1.15);
  pointer-events: none; }
  .mt-modal.is--visible, .is--visible.modal-dialog {
    opacity: 1;
    display: flex !important;
    z-index: 100010; }
  .mt-modal__wrap {
    background-color: #f5f0ec;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
    overflow: auto;
    width: 80%;
    max-width: 600px;
    pointer-events: auto; }
    @media only screen and (max-width: 480px) {
      .mt-modal__wrap {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: none;
        width: 100%; } }
  .mt-modal__header, .title-dialog {
    background-color: #000e23;
    padding: 16px 60px 16px 20px;
    position: relative;
    height: 56px; }
    @media only screen and (max-width: 480px) {
      .mt-modal__header, .title-dialog {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 100; } }
  .mt-modal__title, .title-dialog {
    color: white;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin: 0; }
    @media only screen and (max-width: 480px) {
      .mt-modal__title, .title-dialog {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; } }
  .mt-modal__close-btn, .modal-close {
    color: white;
    cursor: pointer;
    font-size: 20px;
    line-height: 24px;
    padding: 16px 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    transition: all 0.2s ease-out; }
    .mt-modal__close-btn:hover, .modal-close:hover {
      background: rgba(255, 255, 255, 0.2); }
    .mt-modal__close-btn svg, .modal-close svg {
      fill: white; }
  @media only screen and (max-width: 480px) {
    .mt-modal__body, .content-dialog {
      overflow: auto;
      position: absolute;
      top: 56px;
      bottom: 0;
      padding-bottom: 56px;
      width: 100%; } }
  .mt-modal__body-section, .content-dialog {
    background-color: #f5f0ec;
    border-bottom: 1px solid #c7c3c0;
    padding: 20px; }
    .mt-modal__body-section:last-child, .content-dialog:last-child {
      border-bottom: 0; }
    .mt-modal__body-section > :last-child, .content-dialog > :last-child {
      margin-bottom: 0; }
    .mt-modal__body-section.mt-radio, .mt-radio.content-dialog {
      padding: 0; }
  .mt-modal__footer, .actions-dialog {
    background-color: #f5f0ec;
    border-top: 1px solid #c7c3c0;
    padding: 12px 20px; }
    @media only screen and (max-width: 480px) {
      .mt-modal__footer, .actions-dialog {
        padding: 12px;
        position: fixed;
        bottom: 0;
        text-align: center;
        width: 100%; } }

.mt-overlay, #overlay {
  background: #000;
  cursor: pointer;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  transition: z-index 0.1s 0.2s, opacity 0.2s ease-out;
  width: 100%;
  height: 100%;
  z-index: -10; }
  .is--modal-open .mt-overlay, .is--modal-open #overlay {
    opacity: 0.7;
    transition: z-index 0.1s, opacity 0.2s 0.1s ease-out;
    z-index: 100000; }

.mt-media {
  display: flex;
  align-items: stretch; }
  @media only screen and (max-width: 480px) {
    .mt-media {
      display: block; } }
  .mt-media__media, .pic-dialog {
    display: flex;
    align-items: center;
    background-color: white;
    float: left;
    width: 33.333%; }
    @media only screen and (max-width: 480px) {
      .mt-media__media, .pic-dialog {
        float: none;
        width: 100%; } }
  .mt-media__media-bg-icon, .pic-dialog::before {
    color: #666;
    display: block;
    font-size: 100px;
    margin: 0 auto;
    text-align: center; }
  .mt-media__content {
    float: right;
    width: 66.666%; }
    @media only screen and (max-width: 480px) {
      .mt-media__content {
        float: none;
        width: 100%; } }

.modal-dialog {
  background-color: white;
  display: block;
  margin: 0;
  opacity: 1;
  position: static;
  top: 0;
  transform: translateY(0);
  -webkit-animation: moveUpFadeInLegacy 0.3s cubic-bezier(0.51, 0.92, 0.24, 1.15);
  animation: moveUpFadeInLegacy 0.3s cubic-bezier(0.51, 0.92, 0.24, 1.15); }
  .modal-dialog.row {
    margin: 0; }
  @media only screen and (max-width: 480px) {
    .modal-dialog {
      margin-top: 0 !important;
      top: 0 !important; } }

.content-dialog {
  display: block;
  margin: 0;
  border-bottom: 0; }
  @media only screen and (max-width: 480px) {
    .content-dialog.grid-8 {
      position: static; } }

.pic-dialog {
  width: 33.333%; }
  @media only screen and (max-width: 480px) {
    .pic-dialog {
      position: static;
      margin-top: 56px; } }

#overlay {
  display: none; }

.card {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15); }
  .card .content-card {
    padding: 10px; }
  .card a {
    display: block; }
  .card p {
    font-family: coches, arial, verdana;
    font-size: 15px;
    margin: 0;
    color: #000; }
  .card img {
    margin: 0;
    vertical-align: bottom;
    width: 100%; }
  .card h3 {
    font-weight: bold;
    margin: 10px 0 0;
    border-top: 1px solid #f1f1f1;
    padding-top: 10px; }
  .card [class^='icon-'] {
    color: #bbb;
    font-size: 12px; }
    .card [class^='icon-']:before {
      margin-right: 3px;
      font-size: 16px;
      vertical-align: -3px; }
  .card .icon-play {
    font-size: 16px; }
  .card-top {
    position: relative;
    margin-bottom: 20px; }
  .card-status {
    background-color: #fff;
    border-radius: 100px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    font-size: 12px;
    line-height: 23px;
    margin-left: 50%;
    margin-top: 10px;
    padding: 3px 10px;
    position: absolute;
    text-transform: uppercase;
    transform: translate(-50%);
    white-space: nowrap;
    z-index: 2; }
  .card-info {
    background-color: #fff;
    border-radius: 100px;
    bottom: -11px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    font-size: 12px;
    line-height: 23px;
    margin-left: 50%;
    padding: 3px 10px;
    position: absolute;
    text-transform: uppercase;
    transform: translate(-50%);
    white-space: nowrap;
    z-index: 2; }
    .card-info .icon {
      color: #ffffff; }
    .card-info.success {
      background-color: #27ae60;
      color: #fff; }
    .card-info.warning {
      background-color: #f39c12;
      color: #fff; }
    .card-info.error {
      background-color: #e60e27;
      color: #fff; }
    .card-info.moderation {
      background-color: #000e23;
      color: #fff; }
  .card .card-title,
  .card .card-title:hover {
    color: #202020; }
  .card.not-published .card-image,
  .card.not-published .card-title {
    opacity: 0.3; }
  .card-stats {
    background-color: #fcfaf9;
    border-top: 1px solid #f5f0ec;
    padding-top: 15px; }
    .card-stats-col {
      width: 50%;
      float: left;
      margin-bottom: 15px; }
      .card-stats-col h4 {
        font-size: 15px;
        margin-bottom: 5px;
        color: #999999;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px; }
      .card-stats-col h5 {
        font-size: 13px;
        margin-bottom: 5px;
        color: #999999;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px; }

.label-news {
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  border-radius: 3px;
  padding: 0 5px;
  font-family: coches, arial, verdana;
  font-size: 14px;
  background-color: #c7c3c0; }

.user-thumb {
  display: block;
  display: flex;
  align-items: center;
  overflow: hidden; }
  .user-thumb, .user-thumb:visited {
    color: #202020; }
  .user-thumb_avatar {
    border-radius: 50%;
    display: block;
    float: left;
    overflow: hidden;
    width: 36px;
    height: 36px;
    margin-right: 8px; }
  .user-thumb_name {
    display: block;
    flex: 1 0 0;
    float: left;
    font-family: 'coches', arial, verdana;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap; }
    .user-thumb_name span {
      display: block; }
  .topbar_main-nav .user-thumb,
  .drop-bubble .user-thumb,
  #nav-gta .user-thumb {
    background-color: #202020;
    background-image: url("../img/user-bg.jpg");
    background-size: cover;
    padding: 8px 16px;
    position: relative; }
    @media only screen and (max-width: 768px) {
      .topbar_main-nav .user-thumb,
      .drop-bubble .user-thumb,
      #nav-gta .user-thumb {
        padding: 20px 16px; } }
    .topbar_main-nav .user-thumb, .topbar_main-nav .user-thumb:visited, .topbar_main-nav .user-thumb:hover, .topbar_main-nav .user-thumb:focus,
    .drop-bubble .user-thumb,
    .drop-bubble .user-thumb:visited,
    .drop-bubble .user-thumb:hover,
    .drop-bubble .user-thumb:focus,
    #nav-gta .user-thumb,
    #nav-gta .user-thumb:visited,
    #nav-gta .user-thumb:hover,
    #nav-gta .user-thumb:focus {
      color: white; }
    .topbar_main-nav .user-thumb > *,
    .drop-bubble .user-thumb > *,
    #nav-gta .user-thumb > * {
      position: relative;
      z-index: 2; }
    .topbar_main-nav .user-thumb:before,
    .drop-bubble .user-thumb:before,
    #nav-gta .user-thumb:before {
      background-color: rgba(255, 255, 255, 0);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 0.2s ease-in-out;
      z-index: 0; }
    .topbar_main-nav .user-thumb:hover:before, .topbar_main-nav .user-thumb:focus:before,
    .drop-bubble .user-thumb:hover:before,
    .drop-bubble .user-thumb:focus:before,
    #nav-gta .user-thumb:hover:before,
    #nav-gta .user-thumb:focus:before {
      background-color: rgba(255, 255, 255, 0.15); }
  .drop-bubble .user-thumb {
    padding: 12px 20px; }

/* // TOAST
// ----------------------------------------------------------------------------
.mt-toast {
    box-sizing: border-box;
    background-color: white;
    border-bottom: 4px solid;
    box-shadow: 0 4px 6px rgba(0,0,0,.2);
    padding-bottom: 24px;
    text-align: center;

    [class^="icon"] {
        display: inline-block;
        font-size: 48px;
        line-height: 1;
        margin-bottom: 8px;
        padding-top: 24px;
        padding-bottom: 0;
        padding-left: 24px;
        padding-right: 24px
    }
    &__title {
        color: white;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 24px;
        padding-right: 24px;
    }
    &__text {
        color: $dark-grey-color;
        font-family: Arial, Verdana;
        font-size: 16px;
        line-height: 1.45;
        margin-bottom: 16px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 24px;
        padding-right: 24px;
    }
    > :last-child {
        margin-bottom: 0;
    }


    // MODIFIERS
    // --------------------------------------------------------
        &--ok {
            color: $green-color;
            .mt-toast__title { background-color: $green-color; }
        }
        &--ok {
            &.mt-toast-fixDrive2 > .mt-Toast-icon {
                fill: $green-color;
            }
        }

        &--info {
            color: $blue-color;
            .mt-toast__title { background-color: $blue-color; }
        }
        &--info {
            &.mt-toast-fixDrive2 > .mt-Toast-icon {
                fill: $blue-color;
            }
        }

        &--warning {
            color: $orange-color;
            .mt-toast__title { background-color: $orange-color; }
        }
        &--warning {
            &.mt-toast-fixDrive2 > .mt-Toast-icon {
                fill: $orange-color;
            }
        }

        &--error {
            color: $red-color;
            .mt-toast__title { background-color: $red-color; }
        }
        &--error {
            &.mt-toast-fixDrive2 > .mt-Toast-icon {
                fill: $red-color;
            }
        }

    &--fixed {
        margin: 0 auto;
        position: fixed;
            top: 50%;
            left: 0;
            right: 0;
        transform: translateY(-50%);
        width: 100%;
        min-width: 168px;
        max-width: 200px;
        z-index: 11000;
    }

    &.mt-toast-fixDrive2 {
        & > .mt-Toast-icon {
            display: inline-block;
            fill: $dark-grey-color;
            height: 4rem;
            padding-top: 1rem;
            width: 4rem;
        }
        &.anim-move-up-fade-in {
            animation-name: none;
        }
    }
} */
.mt-Toast {
  background-color: #fff;
  border-bottom: 4px solid;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding-bottom: 24px;
  text-align: center; }

.mt-Toast-icon {
  display: inline-block;
  fill: #5f5e5d;
  height: 4rem;
  padding-top: 1rem;
  width: 4rem; }

.mt-Toast-title {
  color: #959290;
  font-size: 16px;
  font-weight: 700;
  padding: 5px 10px; }

.mt-Toast-text {
  color: #959290;
  font-size: 16px;
  line-height: 1.45;
  margin-bottom: 16px;
  padding: 0 24px; }

.mt-Toast > :last-child {
  margin-bottom: 0; }

.mt-Toast--ok,
.mt-Toast--ok .mt-Toast-text {
  color: #27ae60; }

.mt-Toast--ok .mt-Toast-icon {
  fill: #27ae60; }

.mt-Toast--info,
.mt-Toast--info .mt-Toast-text {
  color: #4585f2; }

.mt-Toast--info .mt-Toast-icon {
  fill: #4585f2; }

.mt-Toast--warning,
.mt-Toast--warning .mt-Toast-text {
  color: #f39c12; }

.mt-Toast--warning .mt-Toast-icon {
  fill: #f39c12; }

.mt-Toast--error,
.mt-Toast--error .mt-Toast-text {
  color: #e74c3d; }

.mt-Toast--error .mt-Toast-title {
  background-color: #e74c3d; }

.mt-Toast--error .mt-Toast-icon {
  fill: #e74c3d; }

.mt-Toast--fixed {
  left: 0;
  margin: 0 auto;
  max-width: 200px;
  min-width: 168px;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 11000; }

.mt-Toast.anim-move-up-fade-in {
  animation-name: none; }

.mt-tooltip {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3), 0 0 0 2px #fff inset;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 230px;
  z-index: 110; }
  .mt-tooltip:before {
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: inherit;
    border-right-color: transparent;
    border-style: solid;
    border-width: 0 8px 8px;
    content: '';
    display: block;
    margin-left: -8px;
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 0;
    height: 0; }

.mt-error404 {
  background: #fcfaf9;
  height: 100%; }
  .mt-error404-innerWrap {
    background-color: white;
    background-image: url(../img/error404-bg.png);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 70vh;
    height: 60vh;
    max-height: 430px; }
    @media only screen and (min-width: 768px) {
      .mt-error404-innerWrap {
        background-size: auto auto;
        height: 645px;
        max-height: none; } }
  .mt-error404-sign {
    background-image: url(../img/error404-sign.png), url(../img/error404-cone.png), url(../img/error404-cone.png);
    background-position: center 80px, 25% bottom, 70% bottom;
    background-repeat: no-repeat;
    background-size: auto 350px, auto 50px, auto 50px;
    padding-top: 1.25rem;
    height: 60vh;
    max-height: 430px; }
    @media only screen and (min-width: 768px) {
      .mt-error404-sign {
        background-position: center bottom, 25% bottom, 70% bottom;
        background-size: auto auto;
        height: 645px;
        max-height: none; } }
  .mt-error404-logo {
    background-image: url(https://s.ccdn.es/images/coches/brand-refresh/logo-coches.svg);
    margin: 0 1.25rem;
    width: 210px;
    height: 34px; }
  .mt-error404-message {
    padding: 1.25rem; }
    @media only screen and (min-width: 768px) {
      .mt-error404-message {
        padding: 11rem 2.5rem; } }
    .mt-error404-message ul {
      margin-bottom: 1rem; }
  .mt-error404-title {
    font-size: 1.5rem; }
    .mt-error404-title strong {
      display: block;
      font-size: 4.5rem;
      line-height: 5rem;
      margin-bottom: 0.5rem; }

.upldr-top {
  margin-bottom: 10px;
  min-height: 115px; }

.upldr-txt {
  background-image: url("../img/prev-img.png");
  background-position: center top;
  background-repeat: no-repeat;
  font-weight: 200;
  font-size: 18px;
  font-family: "Poppins", Arial, Verdana;
  padding-top: 144px;
  text-align: center; }
  .upldr-txt small {
    color: #666;
    font-size: 14px; }

.upldr-bottom {
  text-align: center; }

.mt-PhotoUploader {
  box-sizing: border-box;
  background-color: #eef0f4;
  border-color: #bfc7d0;
  border-radius: 8px;
  border-style: dashed;
  border-width: 2px;
  padding: 20px 15px;
  transition: background linear 0.2s; }
  .mt-modal .mt-PhotoUploader, .modal-dialog .mt-PhotoUploader {
    border: 0; }
  .mt-PhotoUploader .info-field {
    font-size: 15px;
    color: #999;
    padding-left: 0 !important; }
  .mt-PhotoUploader-item {
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.15);
    display: inline-block;
    margin: 0 0.25rem 1rem;
    position: relative;
    width: 130px; }
  .mt-PhotoUploader-thumb {
    cursor: pointer;
    outline-color: transparent;
    outline-offset: -2px;
    outline-style: solid;
    outline-width: 2px;
    overflow: hidden;
    position: relative;
    width: 130px;
    height: 130px; }
    .mt-PhotoUploader-thumb:hover {
      outline-color: #999999; }
    .mt-PhotoUploader-thumb img {
      display: block;
      transition: opacity 1s ease;
      width: 100%;
      height: 100%; }
  .mt-PhotoUploader-tooltip {
    background: #000e23;
    color: #ffffff;
    display: none;
    font-size: 1em;
    line-height: 0.75rem;
    padding: 0.25rem;
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%; }
  .mt-PhotoUploader-progressBar {
    background: #fff;
    border-radius: 0.5rem;
    display: none;
    padding: 2px;
    position: absolute;
    left: 0.25rem;
    right: 0.25rem;
    bottom: 0.5rem;
    height: 0.625rem; }
  .mt-PhotoUploader-progress {
    background: #27ae60;
    border-radius: 0.5rem;
    height: 100%;
    width: 0; }
  .mt-PhotoUploader-icon {
    background-image: url("../img/sprite-upldr.png");
    background-repeat: no-repeat;
    margin: 0 auto;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 45px; }
    .mt-PhotoUploader-icon--ok {
      background-position: center -25px; }
    .mt-PhotoUploader-icon--ko {
      background-position: center bottom; }
      .mt-PhotoUploader-icon--ko::before {
        color: #ffffff;
        content: 'Reintentar';
        display: block;
        font-size: 6.25rem;
        letter-spacing: 0.05em;
        margin-top: 0.25rem;
        position: absolute;
        top: 100%;
        left: 0;
        text-align: center;
        width: 100%; }
  .mt-PhotoUploader-itemBtn {
    background-color: #ffffff;
    border: 0;
    color: #000e23;
    cursor: pointer;
    display: block;
    font-size: 1em;
    line-height: 0.75rem;
    padding: 0.5rem 0.25rem;
    transition: all 0.4s ease-out;
    width: 100%; }
    .mt-PhotoUploader-itemBtn [class^='icon-'] {
      font-size: 1em;
      vertical-align: middle;
      position: relative;
      top: -1px; }
    .mt-PhotoUploader-itemBtn:hover {
      background-color: #fcfaf9; }
  .mt-PhotoUploader-item.pending img {
    opacity: 0.4; }
  .mt-PhotoUploader-item.uploading .mt-PhotoUploader-progressBar {
    display: block; }
  .mt-PhotoUploader-item.active .mt-PhotoUploader-thumb {
    outline-color: #000e23; }
  .mt-PhotoUploader-item.active .mt-PhotoUploader-tooltip {
    display: block; }
  .mt-PhotoUploader-item.error .mt-PhotoUploader-thumb {
    background-color: #e60e27; }
  .mt-PhotoUploader-item.error img {
    transition: all 0.4s ease-out;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=40)';
    opacity: 0.4; }
  .mt-PhotoUploader-item.error:hover img {
    opacity: 0.2; }

.nodisplay {
  display: none; }

.mt-ProfilePhotoUpload {
  *zoom: 1;
  border: 0;
  padding: 0.01em 0 0 1.5rem;
  margin: 0;
  min-width: 0;
  height: 100%; }
  .mt-ProfilePhotoUpload:before, .mt-ProfilePhotoUpload:after {
    content: ' ';
    display: table; }
  .mt-ProfilePhotoUpload:after {
    clear: both; }
  body:not(:-moz-handler-blocked) .mt-ProfilePhotoUpload {
    display: table-cell; }
  .mt-ProfilePhotoUpload-imgWrap {
    bottom: -1.5rem;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative; }
    @media screen and (max-width: 767px) {
      .mt-ProfilePhotoUpload-imgWrap.drop-bubble--visible .mt-ProfilePhotoUpload-img:after {
        background-color: #000000;
        border: 0;
        bottom: 0;
        content: '';
        display: block;
        height: auto;
        left: 0;
        opacity: 0.7;
        position: fixed;
        right: 0;
        top: 0;
        width: auto;
        z-index: 1; } }
  .mt-ProfilePhotoUpload-img {
    height: 80px;
    width: 80px; }
    .mt-ProfilePhotoUpload-img--editable::before {
      background-color: #000e23;
      border-radius: 50%;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
      color: #ffffff;
      content: '\E011';
      display: block;
      font-family: 'motor-icons';
      font-size: 1em;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      font-weight: 400;
      height: 1.5rem;
      line-height: 24px;
      position: absolute;
      right: 0;
      speak: none;
      text-align: center;
      text-transform: none;
      top: 0;
      width: 1.5rem;
      z-index: 9; }
    .mt-ProfilePhotoUpload-img img {
      display: block;
      border-radius: 50%;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
      width: 100%; }
  .mt-ProfilePhotoUpload-controls {
    left: 50%;
    margin: 0;
    padding: 0;
    transform: translateX(-50%);
    z-index: 9; }
    .mt-ProfilePhotoUpload-controls::before {
      border-top-color: transparent;
      border-left-color: transparent;
      border-bottom-color: #000e23;
      border-right-color: transparent;
      border-style: solid;
      border-width: 0 8px 8px;
      content: '';
      display: block;
      margin-left: -8px;
      pointer-events: none;
      position: absolute;
      bottom: 100%;
      left: 50%;
      width: 0;
      height: 0;
      transform: translateY(-4px); }
    @media screen and (max-width: 767px) {
      .mt-ProfilePhotoUpload-controls {
        border: 0;
        display: none;
        padding: 0.5rem 0;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 70%; }
        .mt-ProfilePhotoUpload-controls:before {
          display: none; } }
  .mt-ProfilePhotoUpload-label {
    background-color: transparent;
    border: 0;
    color: #333333 !important;
    cursor: pointer;
    display: block;
    font-family: "Poppins" !important;
    font-size: 1em !important;
    font-weight: 400 !important;
    line-height: 2rem !important;
    margin: 0 !important;
    padding: 0 1rem;
    text-align: left;
    white-space: nowrap;
    width: 100%; }
    .mt-ProfilePhotoUpload-label:hover {
      color: #000e23 !important; }
  .mt-ProfilePhotoUpload-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .mt-ProfilePhotoUpload-title {
    color: #ffffff;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.25rem;
    vertical-align: bottom; }
    .mt-ProfilePhotoUpload-title p {
      margin-bottom: 0; }
  .mt-ProfilePhotoUpload .mt-Loading::after {
    border-radius: 50%; }

.mt-Loading:after {
  background-color: rgba(0, 0, 0, 0.5);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 0; }

.mt-Loading--spinner {
  animation: rot 0.6s infinite linear;
  border-bottom: 4px solid rgba(255, 255, 255, 0.3);
  border-left: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  border-right: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid rgba(255, 255, 255, 0.8);
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 10; }

@keyframes rot {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

/*
<h3>Mobile</h3>
<div class="kss-Boxtransparent kss-Boxtransparent--mobile u-mb--large">
  <div class="iframify">
    <div class="mt-SmartBanner">
      <button class="mt-SmartBanner-closeBtn" data-banner-top="close">
        <span class="u-hide--visually">Cerrar</span>
        <svg class="mt-Icon mt-Icon--light">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../img/sprite/icons.svg#close"></use>
        </svg>
      </button>
      <img src="http://placehold.it/70x70?text=icon" width="70" height="70" />
      <div class="mt-SmartBanner-content">
        <div class="mt-SmartBanner-info">
          <p>Coches.net <strong>para IOS</strong></p>
          <p class="mt-SmartBanner-stars">
             <svg class="mt-Icon mt-Icon--small">
               <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../img/sprite/icons.svg#star-full"></use>
            </svg>
             <svg class="mt-Icon mt-Icon--small">
               <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../img/sprite/icons.svg#star-full"></use>
            </svg>
             <svg class="mt-Icon mt-Icon--small">
               <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../img/sprite/icons.svg#star-full"></use>
            </svg>
             <svg class="mt-Icon mt-Icon--small">
               <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../img/sprite/icons.svg#star-full"></use>
            </svg>
             <svg class="mt-Icon mt-Icon--small">
               <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../img/sprite/icons.svg#star-empty"></use>
            </svg>
            (999.999)
          </p>
        </div>
        <div class="mt-SmartBanner-cta">
          <button class="mt-SmartBanner-installBtn mt-Button mt-Button--secondary mt-Button--small">Instalar</button>
        </div>
      </div>
    </div>
  </div>
</div>
*/
.mt-SmartBanner {
  align-items: center;
  background-color: #f5f0ec;
  border-bottom: 1px solid #c7c3c0;
  bottom: 0;
  display: flex;
  left: 0;
  padding: 0.5rem;
  padding-left: 0;
  position: fixed;
  right: 0; }
  @media screen and (min-width: 600px) {
    .mt-SmartBanner {
      display: none; } }
  .mt-SmartBanner-closeBtn {
    background: transparent;
    border: 0;
    color: #c7c3c0;
    padding: 0; }
  .mt-SmartBanner-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 12px;
    justify-content: flex-end; }
    @media screen and (min-width: 600px) {
      .mt-SmartBanner-content {
        flex-direction: row; } }
  .mt-SmartBanner-info p {
    margin-bottom: 0;
    text-align: right; }
    @media screen and (max-width: 600px) {
      .mt-SmartBanner-info p:last-child {
        margin-bottom: 0.25rem; } }
  .mt-SmartBanner-stars {
    align-items: center;
    display: flex;
    justify-content: flex-end; }
    .mt-SmartBanner-stars .mt-Icon {
      fill: #f39c12;
      padding: 0;
      width: 1rem; }
      .mt-SmartBanner-stars .mt-Icon:last-child {
        margin-right: 0.5rem; }
  .mt-SmartBanner-cta {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-left: 0.75rem; }

.mt-SmartBanner {
  z-index: 5001; }
  .mt-SmartBanner * {
    box-sizing: border-box; }
  .mt-SmartBanner img {
    display: block; }
  .mt-SmartBanner p {
    font-size: 12px; }
  .mt-SmartBanner-info p span,
  .mt-SmartBanner-info p strong {
    font-size: 12px; }
  .mt-SmartBanner-info p strong {
    font-weight: bold; }

body .mt-SmartBanner-demo {
  position: relative; }

.mt-SmartBanner-cta button {
  -webkit-appearance: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Open Sans', Arial, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: 2.5rem;
  line-height: 1.5rem;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  border-color: #4585f2;
  overflow: visible;
  outline: none;
  transition: all 0.3s ease-out;
  background-color: #4585f2;
  font-size: 0.875rem;
  height: 1.75rem;
  line-height: 1.25rem;
  padding: 0.25rem 1rem; }

.mt-SmartBanner-closeBtn {
  color: #cac6c3;
  background: transparent;
  border-width: 0;
  padding: 0;
  align-items: flex-start;
  text-align: center;
  cursor: default;
  letter-spacing: normal;
  word-spacing: normal; }
  .mt-SmartBanner-closeBtn .mt-Icon {
    display: inline-block;
    fill: #5f5e5d;
    height: 2rem;
    padding: 0.375rem;
    vertical-align: top;
    width: 2rem; }
  .mt-SmartBanner-closeBtn .u-hide--visually {
    clip: rect(0 0 0 0);
    height: 1px;
    position: absolute;
    width: 1px;
    border-width: 0;
    border-style: initial;
    border-color: initial;
    margin: -1px;
    overflow: hidden;
    padding: 0; }

.mt-SmartBanner-info p {
  line-height: 18px;
  font-size: 12px; }
  @media screen and (max-width: 600px) {
    .mt-SmartBanner-info p:last-child {
      margin-bottom: 0.25rem; } }

.mt-SmartBanner-stars .mt-Icon {
  height: 1rem; }

.mt-Collapsible {
  margin-bottom: 15px; }
  .mt-Collapsible div,
  .mt-Collapsible li,
  .mt-Collapsible p {
    font-family: "Poppins", Arial, Verdana, sans-serif;
    font-size: 14px; }
  .mt-Collapsible h4 {
    color: #434b51; }
  .mt-Collapsible-description {
    font-size: 12px;
    padding: 15px 0; }
  .mt-Collapsible-section {
    color: #000e23;
    font-size: 14px;
    font-weight: bold;
    margin: 15px 0; }
  .mt-Collapsible input + .mt-Collapsible-label {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between; }
    .mt-Collapsible input + .mt-Collapsible-label .mt-Collapsible-label-title {
      align-self: center;
      margin: 0; }
  .mt-Collapsible input ~ .mt-Collapsible-detail {
    display: none; }
  .mt-Collapsible input:checked + .mt-Collapsible-label-arrow {
    transform: rotate(180deg); }
  .mt-Collapsible input:checked ~ .mt-Collapsible-detail {
    display: block; }
  .mt-Collapsible-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
    padding: 0; }
    .mt-Collapsible-list-item {
      margin-bottom: 15px;
      width: 45%; }
      @media only screen and (max-width: 768px) {
        .mt-Collapsible-list-item {
          width: 100%; } }
      .mt-Collapsible-list-item-no-checkbox {
        margin-left: 40px; }
    .mt-Collapsible-list-title {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 8px;
      color: #434b51; }
    .mt-Collapsible-list-detail {
      color: #606f80;
      font-size: 10px;
      margin-bottom: 10px; }
    .mt-Collapsible-list a {
      font-size: 14px;
      font-weight: 600;
      color: #000e23;
      text-decoration: underline; }
  .mt-Collapsible-option {
    justify-content: space-between;
    align-items: center;
    padding: 25px 0; }
    @media only screen and (min-width: 768px) {
      .mt-Collapsible-option {
        display: flex; } }
    @media only screen and (max-width: 768px) {
      .mt-Collapsible-option {
        padding-top: 10px; } }
    .mt-Collapsible-option-box {
      justify-content: space-between;
      align-items: center;
      position: relative; }
      @media only screen and (min-width: 768px) {
        .mt-Collapsible-option-box {
          display: flex; } }
      .mt-Collapsible-option-box .alert {
        display: none;
        flex-direction: column;
        font-size: 14px;
        height: 100%;
        padding: 20px;
        position: absolute;
        width: 100%; }
        .mt-Collapsible-option-box .alert.alert-show {
          display: inline-flex; }
    .mt-Collapsible-option-info {
      flex-basis: 50%; }
      .mt-Collapsible-option-info .mt-Collapsible-section {
        padding-bottom: 5px; }
      .mt-Collapsible-option-info .mt-Collapsible-description {
        padding: 0; }
    @media only screen and (max-width: 768px) {
      .mt-Collapsible-option-button {
        margin-top: 25px; } }
    .mt-Collapsible-option-button .mt-Button {
      font-size: 14px;
      width: 272px; }
      @media only screen and (max-width: 768px) {
        .mt-Collapsible-option-button .mt-Button {
          width: 100%; } }

.mt-InputCheckbox {
  cursor: pointer;
  display: block;
  position: relative; }
  .mt-InputCheckbox-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .mt-InputCheckbox-label {
    display: block;
    line-height: 1.25rem;
    padding-left: 2.5rem;
    padding-top: 0.125rem; }
    .mt-InputCheckbox-label::before {
      background-color: #ffffff;
      border: 1px solid #959290;
      border-radius: 0.25rem;
      content: '';
      display: block;
      height: 1.5rem;
      left: 0;
      position: absolute;
      top: 0;
      width: 1.5rem; }
  .mt-InputCheckbox-input:checked + .mt-InputCheckbox-label::before {
    background: #000e23 url(../img/check.svg) no-repeat center center;
    background-size: 70% auto;
    border-color: #000e23; }
  .mt-InputCheckbox--small .mt-Checkbox-label {
    font-size: 12px;
    line-height: 1rem; }
  .mt-InputCheckbox--icon .mt-Checkbox-label {
    padding-left: 0; }
    .mt-InputCheckbox--icon .mt-Checkbox-label::before {
      display: inline-block;
      position: inherit;
      vertical-align: middle; }
  .mt-InputCheckbox--icon .mt-Icon {
    display: inline-block;
    vertical-align: middle;
    fill: #27ae60; }
  .is--invalid .mt-InputCheckbox-label::before {
    border-color: #e60e27; }

.mt-OptionGroup {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%; }
  .no-flexwrap .mt-OptionGroup {
    *zoom: 1; }
  .no-flexwrap .mt-OptionGroup::before, .no-flexwrap .mt-OptionGroup::after {
    content: ' ';
    display: table; }
  .no-flexwrap .mt-OptionGroup::after {
    clear: both; }
  .mt-OptionGroup-item {
    flex-basis: 2.25rem;
    flex-grow: 1;
    flex-shrink: 1; }
    .no-flexwrap .mt-OptionGroup-item {
      float: left; }
  .mt-OptionGroup-label {
    background-color: #ffffff;
    border: 1px solid #cac6c3;
    border-radius: 0.25rem;
    cursor: pointer;
    display: block;
    font-family: "Poppins", Arial, Verdana;
    font-size: 0.875rem;
    line-height: 1.75rem;
    padding: 0.25rem 0.5rem;
    width: 100%;
    border-left-width: 0;
    border-radius: 0;
    height: 2.25rem;
    padding: 0.25rem 0;
    text-align: center; }
    .mt-OptionGroup-item:first-child .mt-OptionGroup-label {
      border-left: 1px solid #e1dedc;
      border-radius: 0.25rem 0 0 0.25rem; }
    .mt-OptionGroup-item:last-child .mt-OptionGroup-label {
      border-radius: 0 0.25rem 0.25rem 0; }
  .mt-OptionGroup-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .mt-OptionGroup-input:focus + .mt-OptionGroup-label {
    border-color: #959290; }
  .mt-OptionGroup-input:checked + .mt-OptionGroup-label {
    background-color: #242f44;
    color: #ffffff; }

/* Changes in PTA form before Cross-Pta implementation  */
#pg-Pta .mt-Topbar-ptaMobile {
  display: none; }

#pg-Pta .mt-OptionGroup {
  display: inline-flex;
  width: auto; }
  #pg-Pta .mt-OptionGroup label {
    font-family: "Poppins", Arial, Verdana;
    font-size: 14px;
    font-weight: normal;
    color: #606f80;
    padding: 7px;
    margin: 0; }

#pg-Pta .mt-OptionGroup-input:checked + .mt-OptionGroup-label {
  background-color: #000e23;
  color: #ffffff;
  font-weight: 600; }

#pg-Pta .mt-OptionGroup-item {
  min-width: 140px; }

#pg-Pta .pta-vertical-layout span.comentariosMessage {
  display: none; }

#pg-Pta .pta-form {
  max-width: 590px; }

#pg-Pta .mt-Topbar-pta {
  display: none; }

#pg-Pta #quality-ad {
  visibility: visible; }

@media only screen and (max-width: 767px) {
  #pg-Pta .pta-form {
    max-width: inherit; }
  #pg-Pta .mt-OptionGroup {
    display: flex;
    width: 100%; }
  #pg-Pta .mt-OptionGroup-item {
    min-width: auto; }
  #pg-Pta .pta_info_adicional {
    min-width: auto; } }

.mt-UserArea-sectionImage {
  width: 220px; }

@media screen and (min-width: 768px) {
  .mt-UserArea-sectionImage {
    width: 270px; } }

footer {
  display: none; }

/* Tablets and up */
@media only screen and (min-width: 768px) {
  footer {
    display: block; }
  #social {
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px; }
    #social .fleft a {
      display: inline-block;
      width: 70px;
      height: 80px;
      color: #606f80;
      line-height: 75px;
      text-align: center;
      float: left;
      font-size: 22px; }
      #social .fleft a span {
        vertical-align: middle; }
      #social .fleft a:hover span {
        color: #eef0f4; }
    #social .fleft .separator {
      display: inline-block;
      width: 1px;
      height: 50px;
      background-color: #d1cdcc;
      vertical-align: middle;
      float: left;
      margin-top: 15px; }
    #social .facebook:hover {
      background-color: #3b5998; }
    #social .twitter:hover {
      background-color: #00aced; }
    #social .google:hover {
      background-color: #dd4b39; }
    #social .youtube:hover {
      background-color: #bb0000; }
    #social .fright {
      padding: 20px 20px 10px; }
      #social .fright h3 {
        margin: 0 20px 0 0;
        color: #606f80;
        line-height: 40px;
        font-size: 14px; }
      #social .fright input {
        width: 150px;
        border-radius: 3px 0 0 3px;
        font-size: 15px;
        height: 37px;
        float: left;
        border: none;
        margin: 0; }
  .go-input {
    float: left;
    border-radius: 0 3px 3px 0;
    background-color: #f5f0ec; }
    .go-input span {
      color: #606f80;
      line-height: 37px;
      padding: 0 10px;
      font-size: 15px; }
  #footer-links {
    margin-top: 40px; }
    #footer-links a {
      color: #606f80;
      font: 14px coches, arial, verdana; }
      #footer-links a:hover {
        color: #000e23; }
    #footer-links ul {
      margin-right: 30px;
      padding: 0; }
    #footer-links h4 {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 8px;
      color: #000e23; }
    #footer-links .appstore,
    #footer-links .playstore {
      border: 1px solid #606f80;
      border-radius: 3px;
      width: 140px;
      height: 50px;
      display: inline-block;
      margin-right: 20px; }
    #footer-links .appstore {
      background: url(/img/sprite2015.png) no-repeat 0 -68px; }
    #footer-links .playstore {
      background: url(/img/sprite2015.png) no-repeat 0 -118px; }
  #schibsted {
    margin: 40px 0; }
    #schibsted p {
      font-size: 12px;
      color: #606f80; }
    #schibsted a {
      font: 12px coches, arial, verdana;
      color: #606f80;
      font-weight: 600; }
    #schibsted a:hover {
      color: #434b51;
      border: none; }
  .logo-schibsted {
    display: inline-block;
    width: 140px;
    height: 30px;
    background: url(../img/sprite2015.png) no-repeat 0 -162px;
    margin-bottom: 20px; }
  .logo-adevinta {
    display: inline-block;
    width: 114px;
    height: auto;
    margin-bottom: 20px; } }

/* page(980) + (20 marginleft + 20 marginright) and up */
@media only screen and (min-width: 1020px) {
  #social .fright h3 {
    font-size: 24px; }
  #social .fright input {
    width: 200px; }
  #social .fleft a {
    width: 80px; }
  #footer-links ul {
    margin-right: 50px; }
  #footer-links a {
    font-size: 14px; }
  #footer-links p {
    font-size: 12px; } }

.mt-SocialNetworks {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 8px;
  height: 72px; }
  .mt-SocialNetworks-item {
    display: inline-block; }
    .mt-SocialNetworks-item:hover .mt-SocialNetworks-icon {
      fill: #434b51; }
    .mt-SocialNetworks-item:hover + .mt-SocialNetworks-item .mt-Icon {
      border-color: transparent; }
  .mt-SocialNetworks-link {
    display: block;
    padding: 24px 16px;
    text-align: center; }
    .mt-SocialNetworks-link:hover .mt-Icon {
      fill: #fff; }
    .mt-SocialNetworks-link--youtube:hover {
      background: #bb0000; }
      .mt-SocialNetworks-link--youtube:hover .mt-Icon {
        border-left: 1px solid #bb0000; }
    .mt-SocialNetworks-link--twitter:hover {
      background: #55acee; }
      .mt-SocialNetworks-link--twitter:hover .mt-Icon {
        border-left: 1px solid #55acee; }
    .mt-SocialNetworks-link--instagram:hover {
      background: #8a3ab9; }
      .mt-SocialNetworks-link--instagram:hover .mt-Icon {
        border-left: 1px solid #8a3ab9; }
    .mt-SocialNetworks-link--facebook:hover {
      background: #3b5998; }
      .mt-SocialNetworks-link--facebook:hover .mt-Icon {
        border-left: 1px solid #3b5998; }
    .mt-SocialNetworks-link--tiktok:hover {
      background: #69c9d0; }
      .mt-SocialNetworks-link--tiktok:hover .mt-Icon {
        border-left: 1px solid #69c9d0; }
  .mt-SocialNetworks .mt-Icon {
    display: block;
    fill: #434b51;
    height: 24px;
    width: 24px;
    padding: 0; }

.topbar .pull {
  display: none; }

.topbar_logo-wrap {
  background: #fff;
  padding-left: 24px;
  height: 64px; }

.topbar_logo {
  background: url(https://s.ccdn.es/images/logo-coches.svg) no-repeat 50% !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% !important;
  display: block;
  height: 64px;
  overflow: hidden;
  text-decoration: none !important;
  text-indent: 200%;
  width: 148px !important;
  white-space: nowrap; }
  .lt-ie8 .topbar_logo {
    text-indent: -100%; }
  @media screen and (min-device-pixel-ratio: 1.5) {
    .topbar_logo {
      background-image: url(/m2/images/sprite2015-x2.png); } }
  @media only screen and (max-width: 767px) {
    .topbar_logo {
      background-size: 130px auto;
      width: 130px; } }
  @media only screen and (min-width: 768px) and (max-width: 969px) {
    .topbar_logo {
      background-position: 0 100%;
      width: 64px; } }

@media only screen and (min-width: 768px) {
  .topbar {
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15); }
    .topbar_logo-wrap {
      float: left; }
    .topbar_menus {
      padding-left: 64px;
      padding-right: 24px;
      width: 100%; }
    .topbar_user {
      float: right;
      margin-right: 16px; }
    .topbar_main-nav {
      float: left; }
    .topbar_pta {
      float: right; }
    .topbar_user {
      position: relative; }
      .topbar_user > .user-thumb {
        font-size: 14px;
        padding: 14px 8px; }
        .topbar_user > .user-thumb .user-thumb_name {
          max-width: 100px; }
      .topbar_user.drop-bubble_wrap > a:before,
      .topbar_user.drop-bubble_wrap > span:before {
        left: 26px; }
      .topbar_user .drop-bubble {
        margin-right: -32px;
        position: absolute;
        right: 100%;
        transform: translateX(50%);
        min-width: 230px; }
    .topbar_main-nav > ul > li {
      float: left; }
      .topbar_main-nav > ul > li > .nav_btn {
        cursor: default;
        background-color: transparent;
        padding: 24px 8px;
        transition: all 0.2s ease-in-out; }
        .topbar_main-nav > ul > li > .nav_btn [class^='icon-'] {
          display: none; }
    .topbar_main-nav .nav_btn {
      font-size: 14px;
      line-height: 16px; }
      .topbar_main-nav .nav_btn.has--border-top {
        border-top: none; }
    .topbar_main-nav .drop-bubble {
      white-space: nowrap; }
    .topbar_pta {
      padding: 16px 0; }
      .topbar_pta .btn {
        display: block;
        line-height: 24px;
        padding: 4px 12px;
        height: 32px; }
        .topbar_pta .btn:active:not(.btn-disabled):not(.btn-border-dark):not(.btn-border-light) {
          padding: 4px 12px; } }

@media only screen and (min-width: 970px) {
  .topbar_menus {
    padding-left: 180px; }
  .topbar_main-nav > ul > li {
    float: left; }
    .topbar_main-nav > ul > li > .nav_btn {
      padding: 24px 12px; }
  .topbar_main-nav .nav_btn {
    font-size: 16px; } }

@media only screen and (max-width: 767px) {
  .topbar_logo-wrap {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    padding-left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5000; }
  .topbar .nav-slide_open-btn {
    float: left;
    padding: 20px 16px; }
  .topbar.nav-slide .user-thumb {
    color: white;
    padding: 24px 16px; }
  .topbar.nav-slide .drop-bubble {
    border: 0;
    position: static; }
    .topbar.nav-slide .drop-bubble:before {
      display: none; }
  .topbar.nav-slide .btn {
    background: transparent;
    border: 0;
    box-shadow: none;
    display: block;
    font-family: "Poppins", Arial, Verdana, arial, verdana;
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    padding: 12px 20px;
    text-align: left; }
    .topbar.nav-slide .btn [class^='icon-'] {
      color: #999999;
      font-size: 20px;
      margin-right: 32px; }
    .topbar.nav-slide .btn,
    .topbar.nav-slide .btn [class^='icon-'], .topbar.nav-slide .btn:visited,
    .topbar.nav-slide .btn:visited [class^='icon-'] {
      color: #000e23 !important; }
    .topbar.nav-slide .btn:hover,
    .topbar.nav-slide .btn:hover [class^='icon-'], .topbar.nav-slide .btn:focus,
    .topbar.nav-slide .btn:focus [class^='icon-'] {
      color: #242f44 !important; } }
