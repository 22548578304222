// TOPBAR
// ----------------------------------------------------------------------------
.topbar {
  // LAYOUT
  // --------------------------------------------------------
  .pull {
    display: none;
  }

  // LOGO
  // --------------------------------------------------------
  &_logo-wrap {
    background: #fff;
    padding-left: 24px;
    height: 64px;
  }
  &_logo {
    background: $bg-topbar-user-brand !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% !important;
    display: block;
    height: 64px;
    overflow: hidden;
    text-decoration: none !important;
    text-indent: 200%;
    width: 148px !important;
    white-space: nowrap;

    .lt-ie8 & {
      text-indent: -100%;
    }

    @media screen and (min-device-pixel-ratio: 1.5) {
      background-image: url(/m2/images/sprite2015-x2.png);
    }

    @media only screen and (max-width: $mq-tablet - 1) {
      background-size: 130px auto;
      width: 130px;
    }

    @media only screen and (min-width: $mq-tablet) and (max-width: $mq-desktop - 1) {
      background-position: 0 100%;
      width: 64px;
    }
  }
}

// TABLET STYLES
// ----------------------------------------------------------------------------
@media only screen and (min-width: $mq-tablet) {
  .topbar {
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);

    // LAYOUT
    // --------------------------------------------------------
    &_logo-wrap {
      float: left;
    }
    &_menus {
      padding-left: 64px;
      padding-right: 24px;
      width: 100%;
    }
    &_user {
      float: right;
      margin-right: 16px;
    }

    &_main-nav {
      float: left;
    }

    &_pta {
      float: right;
    }

    // LOGO
    // --------------------------------------------------------
    &_logo {
    }

    // USER
    // --------------------------------------------------------
    &_user {
      position: relative;

      > .user-thumb {
        font-size: 14px;
        padding: 14px 8px;

        .user-thumb_name {
          max-width: 100px;
        }
      }
      &.drop-bubble_wrap {
        > a:before,
        > span:before {
          left: 26px;
        }
      }
      .drop-bubble {
        margin-right: -32px;
        position: absolute;
        right: 100%;
        transform: translateX(50%);
        min-width: 230px;
      }
    }

    // MAIN NAV
    // --------------------------------------------------------
    &_main-nav {
      > ul {
        > li {
          float: left;

          > .nav_btn {
            cursor: default;
            background-color: transparent;
            padding: 24px 8px;
            transition: all 0.2s ease-in-out;

            [class^='icon-'] {
              display: none;
            }
          }
        }
      }
      .nav_btn {
        font-size: 14px;
        line-height: 16px;

        &.has--border-top {
          border-top: none;
        }
      }
      .drop-bubble {
        white-space: nowrap;
      }
    }

    // PTA
    // --------------------------------------------------------
    &_pta {
      padding: 16px 0;

      .btn {
        display: block;
        line-height: 24px;
        padding: 4px 12px;
        height: 32px;

        &:active:not(.btn-disabled):not(.btn-border-dark):not(.btn-border-light) {
          padding: 4px 12px;
        }
      }
    }
  }
}

// DESKTOP STYLES
// ----------------------------------------------------------------------------
@media only screen and (min-width: $mq-desktop) {
  .topbar {
    // LAYOUT
    // --------------------------------------------------------
    &_menus {
      padding-left: 180px;
    }

    // MAIN NAV
    // --------------------------------------------------------
    &_main-nav {
      > ul {
        > li {
          float: left;

          > .nav_btn {
            padding: 24px 12px;
          }
        }
      }
      .nav_btn {
        font-size: 16px;
      }
    }
  }
}

// MOBILE STYLES
// ----------------------------------------------------------------------------
@media only screen and (max-width: $mq-tablet - 1) {
  .topbar {
    &_logo-wrap {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
      padding-left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 5000;
    }
    .nav-slide_open-btn {
      float: left;
      padding: 20px 16px;
    }

    &.nav-slide {
      .user-thumb {
        color: white;
        padding: 24px 16px;
      }
      .drop-bubble {
        border: 0;
        position: static;

        &:before {
          display: none;
        }
      }
      .btn {
        background: transparent;
        border: 0;
        box-shadow: none;
        display: block;
        font-family: $font, arial, verdana;
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        padding: 12px 20px;
        text-align: left;

        [class^='icon-'] {
          color: $dark-grey-color;
          font-size: 20px;
          margin-right: 32px;
        }
        &,
        &:visited {
          &,
          [class^='icon-'] {
            color: $link-color !important;
          }
        }
        &:hover,
        &:focus {
          &,
          [class^='icon-'] {
            color: $link-hover-color !important;
          }
        }
      }
    }
  }
}
