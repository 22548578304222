﻿.table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #ddd;
  font-family: Coches, Verdana;

  td,
  th {
    font-size: 17px;
    margin: 0;
    overflow: visible;
    padding: 0.7em 1em;
    border-width: 0 0 1px;
    border-bottom: 1px solid #ddd;
  }

  thead {
    background: #e0e0e0;
    color: #000;
    text-align: left;
    vertical-align: bottom;
  }
}

.table td {
  background-color: transparent;
}

.table-zebra tbody tr:nth-child(odd) {
  background-color: #f5f5f5;
}
.table-zebra td,
.table-zebra th {
  border-bottom: 1px solid transparent;
}
.table-zebra tbody > tr:last-child td {
  border-bottom: 1px solid #cbcbcb;
}
